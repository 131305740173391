import React, { useState, useEffect } from "react";
import axios from "../../interceptor";
import endpoints from "../../endpoint";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";

function AclEditForm() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [permissionlist, setPermissionList] = useState([]);
	const [roleName, setRoleName] = useState([]);
	const [selectedPermissionFromBackend, setSelectedPermissionsFromBackend] =
		useState([]);

	const { id } = useParams();
	let editedId = id;

	const handleCheckboxChange = (id) => {
		const indexofParticularId = selectedPermissionFromBackend.indexOf(id);
		if (indexofParticularId === -1) {
			setSelectedPermissionsFromBackend([...selectedPermissionFromBackend, id]);
		} else {
			setSelectedPermissionsFromBackend(
				selectedPermissionFromBackend.filter((itemId) => itemId !== id)
			);
		}
	};

	async function getPermissionList() {
		try {
			const res = await axios.get(endpoints.permissionslistinacl);
			if (res.status === 200) {
				let data = res.data.data;
				setPermissionList(data);
			} else {
				console.log("Error: Unable to fetch Dashboard data.");
			}
		} catch (error) {
			console.log(
				"Error occurred while making the HTTP request:",
				error.message
			);
		}
	}

	async function getAclDetailsWithEditedId() {
		try {
			const res = await axios.get(endpoints.aclGetAll + "?id=" + editedId);
			if (res.status === 200) {
				let name = res.data.data[0];
				setRoleName(name);
				let data = res.data.data[0].permissions.map(
					(item) => item.permission_id
				);
				setSelectedPermissionsFromBackend(data);
			} else {
				console.log("Error: Unable to fetch ACL data.");
			}
		} catch (error) {
			console.log(
				"Error occurred while making the HTTP request:",
				error.message
			);
		}
	}

	async function updateAclDetails() {
		setLoading(true);
		try {
			let payload = {
				id: editedId,
				roleName: roleName.roleName,
				permissions: selectedPermissionFromBackend,
			};
			const res = await axios.post(endpoints.aclUpdate, payload);
			if (res.status === 200) {
				setLoading(false);
				navigate("/access-control-lists");
			} else {
				console.log("Error: Unable to fetch ACL data.");
			}
		} catch (error) {
			setLoading(false);
			console.log(
				"Error occurred while making the HTTP request:",
				error.message
			);
		}
	}

	useEffect(() => {
		getPermissionList();
		getAclDetailsWithEditedId();
	}, []);

	const ToggleButton = ({ isChecked, permissionId, onToggle }) => {
		const handleClick = () => {
			onToggle(permissionId); // Call the parent component's function with the permissionId
		};

		return (
			<div
				className={`toggle-button ${isChecked ? "on" : "off"}`}
				onClick={handleClick}
			>
				<div className="inner-circle"></div>
			</div>
		);
	};

	return (
		<div className="app">
			<div className="layout">
				{/* <Navbar />
            <Sidebar /> */}
				<div className="page-container dynamic-bgcolor">
					<div className="main-content">
						<div>
							<div>
								<h2
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									Edit Access Control List
								</h2>
								<div>
									<button className="btn dynamic-color">
										<Link
											to={"/access-control-lists"}
											style={{ color: "white" }}
										>
											Back
										</Link>
									</button>
								</div>
								<form className="aclcreateform">
									<div>
										<span>Role Name</span>
										<div style={{ width: "70%" }}>
											<Form.Control
												value={roleName.roleName}
												placeholder="Enter role name"
												type="text"
											/>
										</div>
									</div>

									<div>
										<span>Permissions:</span>
									</div>

									<div style={{ marginLeft: "5%" }}>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												width: "100%",
											}}
										>
											{permissionlist.map((list, index) => {
												const isChecked =
													selectedPermissionFromBackend.includes(list.id);
												return (
													<Row
														style={{
															width: "100%",
															display: "flex",
															justifyContent: "center",
															marginTop: "vh",
														}}
														className="align-items-center"
													>
														<Col style={{ padding: "0.5%" }}>
															<label className="form-check-label">
																{list.display_name}
															</label>
														</Col>
														<Col style={{ padding: "0.5%" }}>
															<div>
																<ToggleButton
																	isChecked={isChecked}
																	permissionId={list.id}
																	onToggle={handleCheckboxChange}
																/>
															</div>
														</Col>
													</Row>
												);
											})}
										</div>
									</div>
								</form>
								{loading ? (
									<button
										type="button"
										className="btn dynamic-color login_button_testcolor"
										style={{
											color: "#ffffff",
											marginLeft: "30%",
											backgroundColor: "#176b87",
										}}
										disabled
										onClick={() => {
											updateAclDetails();
										}}
									>
										Update &nbsp;
										<i class="fas fa-spinner fa-spin"></i>
									</button>
								) : (
									<button
										type="button"
										style={{ marginLeft: "30%", marginTop: "5vh" }}
										className="btn dynamic-color login_button_testcolor"
										onClick={() => {
											updateAclDetails();
										}}
									>
										Update
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AclEditForm;
