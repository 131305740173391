import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import Navbar from "../Components/Navbar";

import axios from "../interceptor";
import urls from "../endpoint";
import { Row, Col } from "react-bootstrap";
import { FaXmark, FaRotate } from "react-icons/fa6";
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactApexChart from "react-apexcharts";
import { FaDownload } from "react-icons/fa";

const RevenueReport = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageno, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRevenue, setTotalRevenue] = useState(null);
  const MAX_PAGES_DISPLAYED = 5;
  const [totalpages, setTotalPages] = useState([]);
  const currentPage = totalpages.current_page;
  const npages = totalpages.last_page ? totalpages.last_page : 0;
  const srNo = currentPage * 10 - 10 + 1;
  const startPage = Math.max(1, pageno - Math.floor(MAX_PAGES_DISPLAYED / 2));
  const endPage = Math.min(npages, startPage + MAX_PAGES_DISPLAYED - 1);
  const numbers = [...Array(endPage - startPage + 1).keys()].map(
    (i) => startPage + i
  );
  const currentDate = new Date().toISOString().split("T")[0];
  const [RevenueDetailsForTable, setRevenueDetailsForTable] = useState([]);
  const [RevenueDataDateWise, setRevenueDataDateWise] = useState({
    start_date: currentDate,
    end_date: currentDate,
  });
  const [showModal, setShowModal] = useState(false);
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "revenue-chart",
      },
      xaxis: {
        categories: [],
      }
    },
    series: [
      {
        name: "Revenue",
        data: [],
      },
    ],
  });

  useEffect(() => {
    getRevenueReportData();
  }, [RevenueDataDateWise, pageno, rowsPerPage]);

  useEffect(() => {
    if (
      RevenueDetailsForTable &&
      RevenueDetailsForTable.revenue_details_by_date &&
      RevenueDetailsForTable.revenue_details_by_date.length > 0
    ) {
      setChartData({
        options: {
          ...chartData.options,
          xaxis: {
            categories: RevenueDetailsForTable.revenue_details_by_date.map(
              (item) => item.date
            ),
          },
        },
        series: [
          {
            name: "Revenue",
            data: RevenueDetailsForTable.revenue_details_by_date.map(
              (item) => item.amount
            ),
          },
        ],
      });
    }
  }, [RevenueDetailsForTable, RevenueDataDateWise]);

  const dateHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setRevenueDataDateWise((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getRevenueReportDataReload = () => {
    setLoading(true);
    setIsClicked(true);
    setRevenueDataDateWise({
      start_date: currentDate,
      end_date: currentDate,
    });
    axios
      .post(urls.revenue_report, {
        start_date: currentDate,
        end_date: currentDate,
        page: pageno,
        paginate: 1,
      })
      .then((resp) => {
        const respdata = resp.data;
        setRevenueDetailsForTable(resp.data.data);
        setTotalPages(respdata.data);
        setLoading(false);
        setIsClicked(false);
      })
      .catch((error) => {
        console.error("Error fetching Revenue:", error);
        setLoading(false);
        setIsClicked(false);
      });
  };

  const getRevenueReportData = () => {
    setLoading(true);
    axios
      .post(urls.revenue_report, {
        start_date: RevenueDataDateWise.start_date,
        end_date: RevenueDataDateWise.end_date,
        page: pageno,
        paginate: 1,
      })
      .then((resp) => {
        const respdata = resp.data;
        setRevenueDetailsForTable(resp.data.data);

        setTotalPages(respdata.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Revenue:", error);
        setRevenueDetailsForTable([]);

        setLoading(false);
      });
  };
  const getRevenueReportDataDownload = () => {
    setLoading(true);
    axios
      .post(urls.revenue_report, {
        start_date: RevenueDataDateWise.start_date,
        end_date: RevenueDataDateWise.end_date,
         response_type: "xlsx"
      })
      .then((resp) => {
       
        const url = resp.data.data.link;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.xls");
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Revenue:", error);
      

        setLoading(false);
      });
  };

  return (
    <div className="app">
      <div className="layout">
        <Navbar />
        <Sidebar />
        <div className="page-container dynamic-bgcolor">
          <div>
            <div className="main-content">
              <div style={{ marginTop: "-2%" }}>
                <h3 className="text-center">Revenue Report</h3>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
                <Row className="mt-5" style={{ marginTop: "3%" }}>
                  <Col md={2} style={{ marginTop: "1%" }}>
                    <label>Start Date</label>
                    <input
                      type="date"
                      className={`form-control`}
                      onChange={dateHandler}
                      name="start_date"
                      value={RevenueDataDateWise.start_date}
                    />
                  </Col>
                  <Col md={2} style={{ marginTop: "1%" }}>
                    <label>End Date</label>
                    <input
                      type="date"
                      className={`form-control`}
                      onChange={dateHandler}
                      name="end_date"
                      value={RevenueDataDateWise.end_date}
                    />
                  </Col>
                  <Col style={{ marginLeft: "50%", marginTop: "3%" }}>
                    <FaDownload
                      onClick={getRevenueReportDataDownload}
                      title="Download Report"
                      size={25}
                    />
                  </Col>
                  <Col style={{  marginTop: "3%" }}>
                    <FaRotate
                      onClick={getRevenueReportDataReload}
                      title="Refresh Data"
                      size={25}
                    />
                  </Col>
                </Row>

                {RevenueDetailsForTable &&
                RevenueDetailsForTable.revenue_details_by_date &&
                RevenueDetailsForTable.revenue_details_by_date.length !== 0 ? (
                  <div style={{ marginTop: "2%",justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column' }} className="text-center">
                    <h3 >
                      Total Revenue : {RevenueDetailsForTable.total_revenue}{" "}
                    </h3>
                    <div className="card bg-white rounded shadow mt-5" >
                <div className="card-body">
                    <ReactApexChart
                      options={{
                        ...chartData.options,
                        chart: {
                          toolbar: {
                            show: false,
                          },
                        },
                      }}
                      series={chartData.series}
                      type="line"
                      height={300}
                      width={1100}
                    />
                    </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      marginTop: "13%",
                    }}
                  >
                    <h4>No Data Available</h4>
                  </div>
                )}
              </div>
            </div>
            {loading && (
              <div className="loading-spinner-container">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="70"
                  visible={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .loading-spinner-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
        }
      `}</style>
    </div>
  );
};

export default RevenueReport;
