import React, { useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa";
import axios from "../interceptor";
import { Row, Col } from "react-bootstrap";
import urls from "../endpoint.js";
import { RotatingLines } from "react-loader-spinner";
import ReactApexChart from 'react-apexcharts';

const Dashboard = () => {
  const [dataForCards, setDataForCards] = useState({});
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(false);

  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        height: 300,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          barHeight: '75%', // Set the bar height
          columnWidth: '40%',
          dataLabels: {
            position: 'top',
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: '17px',
          colors: ["#304758"]
        }
      },
      xaxis: {
        categories: [],
        position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            // type: 'gradient',
            // gradient: {
            //   colorFrom: '#D8E3F0',
            //   colorTo: '#BED1E6',
            //   stops: [0, 100],
            //   opacityFrom: 0.4,
            //   opacityTo: 0.5,
            // }
          }
        },
        
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val;
          }
        }
      },
      colors: ['#53047b'],
      title: {
        text: 'Today\'s Payment Method Wise Summary',
        align: 'center',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#263238'
        }
      }

    }
  });

  const reports = () => {
    setLoading(true);
    axios.get(urls.reports_dashboard)
      .then((resp) => {
        const data = resp.data.data;
        console.log(data);
        setDataForCards(data);
        setLoading(false);
        setReportData(true);

    
        setChartData({
          series: [{
            name: 'Amount',
            data: [
              data.today_payment_method_amount.credit_amount,
              data.today_payment_method_amount.debit_amount,
              data.today_payment_method_amount.netbanking_amount,
              data.today_payment_method_amount.upi_amount,
              data.today_payment_method_amount.wallet,
              data.today_payment_method_amount.card
            ]
          }],
          options: {
            ...chartData.options,
            xaxis: {
              ...chartData.options.xaxis,
              categories: ['Credit', 'Debit', 'Net Banking', 'UPI', 'Wallet', 'Card']
            }
          }
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    reports();
  }, []);

  return (
    <div className="page-container">
      <h1 className="dynamic-bgcolor text-center" style={{ marginTop: "5%" }}>Welcome to Iskcon Abids Hyderabad</h1>

      {reportData ? (
        <div>
          <Row style={{ marginTop: "5%" }} className='justify-content-between ml-2'>
            <Col>
              <div className="card bg-white rounded shadow w-75 h-52" style={{ marginLeft: "20%" }}>
                <div className="card-body">
                  <Row className="media align-items-center">
                    <Col sm={2} style={{ marginLeft: "1%", marginTop: "-5%" }}>
                      <div className="avatar avatar-icon avatar-lg avatar-cyan">
                        <i className="anticon anticon-user-add" style={{ color: "#af7ac5" }}></i>
                      </div>
                    </Col>
                    <Col style={{ marginTop: "-6%" }}>
                      <Row style={{ marginTop: "7%" }}>
                        <h5>{dataForCards.total_donations_today}</h5>
                      </Row>
                      <Row>
                        <p style={{ color: "#4a4a4a", marginLeft: "0%", marginTop: "-1%" }}>Total Donations Today</p>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col>
              <div className="card rounded shadow w-75 h-52" style={{ marginLeft: "10%" }}>
                <div className="card-body">
                  <Row className="media align-items-center">
                    <Col sm={2} style={{ marginLeft: "-1%", marginTop: "-5%" }}>
                      <div className="avatar avatar-icon avatar-lg avatar-blue">
                        <i className="anticon" style={{ color: "#f983ca" }}><FaUsers /></i>
                      </div>
                    </Col>
                    <Col style={{ marginTop: "-6%" }}>
                      <Row style={{ marginTop: "7%" }}>
                        <h5>{dataForCards.total_devotees}</h5>
                      </Row>
                      <Row>
                        <p style={{ color: "#4a4a4a", marginLeft: "-3%", marginTop: "-2%" }}>Total Devotees</p>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col>
              <div className="card rounded shadow w-75 h-52">
                <div className="card-body">
                  <Row className="media align-items-center">
                    <Col sm={2} style={{ marginLeft: "-5%", marginTop: "-5%" }}>
                      <div className="avatar avatar-icon avatar-lg avatar-blue">
                        <i className="anticon anticon-dollar"></i>
                      </div>
                    </Col>
                    <Col style={{ marginTop: "-6%" }}>
                      <Row style={{ marginTop: "7%" }}>
                        <h5>{dataForCards.total_revenue_today}</h5>
                      </Row>
                      <Row>
                        <p style={{ color: "#4a4a4a", marginLeft: "-3%", marginTop: "-2%" }}>Total Revenue Today</p>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='justify-content-between ml-2'>
            <Col>
              <div className="card bg-white rounded shadow w-75 h-52" style={{ marginLeft: "20%" }}>
                <div className="card-body">
                  <Row className="media align-items-center">
                    <Col sm={2} style={{ marginLeft: "1%", marginTop: "-5%" }}>
                    <div className="avatar avatar-icon avatar-lg avatar-blue">
                        <i className="anticon anticon-dollar"></i>
                      </div>
                    </Col>
                    <Col style={{ marginTop: "-6%" }}>
                      <Row style={{ marginTop: "7%" }}>
                        <h5>{dataForCards.total_paid_donations_today}</h5>
                      </Row>
                      <Row>
                        <p style={{ color: "#4a4a4a", marginLeft: "0%", marginTop: "-1%" }}>Total Paid Donations <b>(Today)</b></p>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col>
              <div className="card rounded shadow w-75 h-52" style={{ marginLeft: "10%" }}>
                <div className="card-body">
                  <Row className="media align-items-center">
                    <Col sm={2} style={{ marginLeft: "-1%", marginTop: "-5%" }}>
                    <div className="avatar avatar-icon avatar-lg avatar-blue">
                        <i className="anticon anticon-dollar"></i>
                      </div>
                    </Col>
                    <Col style={{ marginTop: "-6%" }}>
                      <Row style={{ marginTop: "7%" }}>
                        <h5>{dataForCards.total_unpaid_donations_today}</h5>
                      </Row>
                      <Row>
                        <p style={{ color: "#4a4a4a", marginLeft: "-3%", marginTop: "-2%" }}>Total Unpaid Donations <b>(Today)</b></p>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col>
              <div className="card rounded shadow w-75 h-52">
                <div className="card-body">
                  <Row className="media align-items-center">
                    <Col sm={2} style={{ marginLeft: "-5%", marginTop: "-5%" }}>
                    <div className="avatar avatar-icon avatar-lg avatar-blue">
                        <i className="anticon anticon-dollar"></i>
                      </div>
                    </Col>
                    <Col style={{ marginTop: "-6%" }}>
                      <Row style={{ marginTop: "7%" }}>
                        <h5>{dataForCards.total_failed_donations_today}</h5>
                      </Row>
                      <Row>
                        <p style={{ color: "#4a4a4a", marginLeft: "-3%", marginTop: "-2%" }}>Total Failed Donations <b>(Today)</b></p>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <div>
            <div id="chart" style={{marginLeft:"20%",marginTop:"7%"}}>
              <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={250} width={700} />
            </div>
            <div id="html-dist"></div>
          </div>
        </div>
      ) : null}

      {loading && (
        <div className="loading-spinner-container" >
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="70"
            visible={true}
          />
        </div>
      )}
      <style jsx>{`
                .loading-spinner-container {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                   
                    z-index: 9999;
                }
            `}</style>
    </div>
  );
};

export default Dashboard;
