import React, { useState, useEffect } from 'react';
import Sidebar from '../Components/Sidebar';
import Navbar from '../Components/Navbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from '../interceptor';
import urls from '../endpoint';

import Select from 'react-select';
import { Row, Col } from 'react-bootstrap'
import { FaXmark } from "react-icons/fa6";
import { RotatingLines } from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import smsimage  from '../assets/next.png'
import cross from '../assets/close.png'
import Tick from '../assets/check-mark.png'
const ManageVolunteers = () => {
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showSms, setShowSms] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [formData, setFormData] = useState({});
    const [vid, setVid] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [leadersData, setLeadersData] = useState([])
    const [formErrors, setFormErrors] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageno, setPageNo] = useState(1);
    const [loading, setLoading] = useState(true);


    const MAX_PAGES_DISPLAYED = 5;

    const [totalpages, setTotalPages] = useState([]);

    const currentPage = totalpages.current_page;
    const npages = totalpages.last_page ? totalpages.last_page : 0;
    const srNo = currentPage * 10 - 10 + 1;
    const startPage = Math.max(1, pageno - Math.floor(MAX_PAGES_DISPLAYED / 2));
    const endPage = Math.min(npages, startPage + MAX_PAGES_DISPLAYED - 1);
    const numbers = [...Array(endPage - startPage + 1).keys()].map(
        (i) => startPage + i
    );
    const handleRowsPerPageChange = (e) => {
        const newRowsPerPage = parseInt(e.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        // onChange(newRowsPerPage);
    };






    const createnotify = () => toast.success("Volunteer Is Created", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const editnotify = () => toast.success("Volunteer Is Updated", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const deletenotify = () => toast.success("Volunteer Is Deleted", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const smsnotify = () => toast.success("Successfully Sent SMS to Volunteer", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const handleCloseCreate = () => { setShowCreate(false) }
    const handleShowCreate = () => {
        setFormData({})
        setFormErrors({});
        setShowCreate(true)
    };

    const handleCloseEdit = () => {

        setShowEdit(false);
    };

    const handleShowEdit = (e, index, data) => {
        e.preventDefault()
        setEditIndex(index);

        setFormData(tableData[index]);
        setFormData((prevData) => ({ ...prevData, id: data.id }))
        console.log(index, tableData, "eeeeeeeeee");
        setFormErrors({});
        setShowEdit(true);
    };
    const handleShowSms= (e, index, data) => {
        e.preventDefault()
        setEditIndex(index);
        setVid(data.vid)
        // setFormData(tableData[index]);
        // setFormData((prevData) => ({ ...prevData, id: data.id }))
        // console.log(index, tableData, "eeeeeeeeee");
        setFormErrors({});
        setShowSms(true);
    };


    const handleCloseDelete = () => setShowDelete(false);

    const handleShowDelete = (index) => {
        console.log(formData)
        console.log(index)
        setEditIndex(index);
        setShowDelete(true);

    };
    const handleCloseSms = () => setShowSms(false);

    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value)
        setFormErrors((prevErrorData)=>({
            ...prevErrorData,
            [name]:null
         }))
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));


    }




    useEffect(() => {
        if ((pageno, rowsPerPage)) {
            getVolunteers()
        }

    }, [pageno, rowsPerPage])



    const createVolunteer = (e) => {
        e.preventDefault();

        setLoading(true);

        axios.post(urls.create_volunteers, {
            name: formData.name,
            mobile_no: formData.mobile_no,
            vid: formData.vid,
            email_id: formData.email_id,
            leader_id: formData.leader_id
        })
            .then((resp) => {

                console.log(resp)

                getVolunteers();
                setShowCreate(false);
                createnotify();
                setLoading(false);
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.data && error.response.data.data.length === 0) {

                    setFormErrors(error.data);
                    console.log(error.data.message);
                } else if (error.response && error.response.data && error.response.data.data && error.response.data.data.length !== 0) {

                    setFormErrors(error.response.data.data);
                }
                setLoading(false);
            });

    };

    const getVolunteers = () => {
        axios.get(urls.get_volunteers, {
            params: {
                page: pageno,
                paginate: 1
            }
        }
        )
            .then((resp) => {
                const respdata = resp.data;
                console.log(respdata.data)
                setTableData(respdata.data.data);

                setTotalPages(respdata.data);
                setLoading(false)
            })
            .catch((error) => {

            });
    }




    const VolunteerUpdate = (e) => {

        e.preventDefault()
        setLoading(true)

        axios.post(urls.update_volunteers, {
            name: formData.name,
            mobile_no: formData.mobile_no,

            email_id: formData.email_id,
            leader_id: formData.leader_id,
            id: formData.id
        })
            .then((resp) => {



                getVolunteers();
                setLoading(false)
                editnotify()
                setShowEdit(false)
            })
            .catch((error) => {
                console.log(error)
                if (error.response && error.response.data && error.response.data.data && error.response.data.data.length === 0) {

                    setFormErrors(error.data);
                    console.log(error.data.message);
                } else if (error.response && error.response.data && error.response.data.data && error.response.data.data.length !== 0) {

                    setFormErrors(error.response.data.data);
                }
                setLoading(false)
            });

    }
    const prePage = () => {
        if (pageno !== 1) {
            setPageNo(pageno - 1);
        }
    }
    const changePage = (id) => {
        console.log(id);
        setPageNo(id);
    }

    const nextPage = () => {
        console.log("pageno", pageno)
        console.log("npgaes", npages)
        if (pageno !== npages) {
            setPageNo(pageno + 1);
        }
    }

    const VolunteerDelete = () => {
        const deleteData = tableData.filter((data, index) => index == editIndex);
        console.log(deleteData)
        setLoading(true)
        axios.post(urls.delete_volunteers, {
            id: deleteData[0].id
        })
            .then((resp) => {
                deletenotify()
                setLoading(false)
                handleCloseDelete();
                getVolunteers()
            })
            .catch((error) => {
            });
    };

    const sendSms = () => {
        
        setLoading(true)
        axios.post(urls.send_sms, {
            vid:vid
        })
            .then((resp) => {
               console.log(resp)
                setLoading(false)
                handleCloseSms();
                getVolunteers()
                smsnotify()
            })
            .catch((error) => {
            });
    };
    console.log(formErrors)

    const getLeaders = () => {
        setLoading(true)
        axios.get(urls.get_leaders)
            .then((resp) => {
                const respdata = resp.data;
                console.log(respdata.data)
                setLeadersData(respdata.data);


                setLoading(false)
            })
            .catch((error) => {

            });
    }
    const options = leadersData && leadersData.length > 0
        ? leadersData.map(leader => ({
            value: leader.id,
            label: leader.name
        }))
        : [];
    console.log(options, "options")
    useEffect(() => {
        getLeaders()
    }, [])
    return (
        <div className='app'>
            <div className='layout'>
                <Navbar />
                <Sidebar />
                <div className='page-container dynamic-bgcolor' >


                    <div>
                        <div className='main-content'>
                            <div style={{ marginTop: "-2%" }}>
                                <h3 className='text-center'>Volunteer Management</h3>

                                <ToastContainer position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    theme="light" />
                                <div style={{ marginTop: "5%" }}>
                                    <Row>
                                        <Col sm={9}>
                                            <div>
                                                <button onClick={handleShowCreate} className='btn btn-md dynamic-color' style={{ color: "white", marginTop: "-2%", marginLeft: "2%" }}>
                                                    Create
                                                </button>

                                            </div>
                                        </Col>

                                    </Row>

                                    <div>

                                    </div>
                                    <Modal show={showCreate} onHide={handleCloseCreate}>
                                        <Modal.Header>
                                            <Modal.Title>Create Volunteer</Modal.Title>
                                            <FaXmark style={{ cursor: 'pointer', marginTop: "2%" }} onClick={handleCloseCreate} />
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form >
                                            <div className='mb-3'>
                                                    <label className='form-label'>Select BV Leader</label>
                                                    <Select
                                                        options={options}
                                                        onChange={(selectedOption) => setFormData((prevData) => ({
                                                            ...prevData,
                                                            leader_id: selectedOption ? selectedOption.value : null
                                                        })
                                                        )}
                                                        placeholder="Select a leader"
                                                    />
                                                  {formErrors.leader_id && <div className='is-invalid'>{formErrors.leader_id}</div>}

                                                </div>
                                                <div className='mb-3'>
                                                    <label className='form-label'>Name</label>
                                                    <input
                                                        type='text'
                                                        className={`form-control ${formErrors?.name ? 'is-invalid' : ''}`}
                                                        name='name'
                                                        value={formData.name}
                                                        onChange={(e) => handleInputChange(e)}
                                                        aria-describedby='emailHelp'
                                                    />
                                                    {console.log(formErrors)}
                                                    {formErrors.name && <div className='invalid-feedback'>{formErrors.name}</div>}
                                                </div>
                                                <div className='mb-3'>
                                                    <label className='form-label'>Email Id</label>
                                                    <input
                                                        type='email'
                                                        className={`form-control ${formErrors?.email_id ? 'is-invalid' : ''}`}
                                                        name='email_id'
                                                        value={formData.email_id}
                                                        onChange={(e) => handleInputChange(e)}
                                                        aria-describedby='emailHelp'
                                                    />
                                                    {formErrors.email_id && <div className='invalid-feedback'>{formErrors.email_id}</div>}

                                                </div>
                                                <div className='mb-3'>
                                                    <label className='form-label'>Mobile Number</label>
                                                    <input
                                                        type='text'
                                                        className={`form-control ${formErrors?.mobile_no ? 'is-invalid' : ''}`}
                                                        name='mobile_no'
                                                        value={formData.mobile_no}
                                                        onChange={(e) => handleInputChange(e)}
                                                        aria-describedby='emailHelp'
                                                    />
                                                    {formErrors.mobile_no && <div className='invalid-feedback'>{formErrors.mobile_no}</div>}
                                                </div>
                                                <div className='mb-3'>
                                                    <label className='form-label'>Volunteer Id</label>
                                                    <input
                                                        type='text'
                                                        className={`form-control ${formErrors?.vid ? 'is-invalid' : ''}`}
                                                        name='vid'
                                                        value={formData.vid}
                                                        onChange={(e) => handleInputChange(e)}
                                                        aria-describedby='emailHelp'
                                                    />
                                                    {formErrors.vid && <div className='invalid-feedback'>{formErrors.vid}</div>}

                                                </div>
                                                

                                                {console.log(formErrors.message)}
                                                {formErrors.message && <div className='is-invalid'>{formErrors.message}</div>}
                                                <div className='mt-2'>
                                                    <button onClick={createVolunteer} className='btn btn-md dynamic-color' style={{ color: "white" }}>
                                                        Submit
                                                    </button>
                                                    <button className='btn btn-md' style={{ backgroundColor: "darkgrey", marginLeft: "2%", color: "white" }} onClick={handleCloseCreate}>
                                                        Close
                                                    </button>
                                                </div>
                                            </form>
                                        </Modal.Body>
                                    </Modal>


                                  <div style={{  overflowX:"auto",overflowY:"hidden" }}>
                                  <table className='table mt-4'>
                                        <thead>
                                            <tr>
                                                <th>Volunteer Id</th>
                                                <th>Name</th>
                                                <th>Associated Leader</th>
                                                <th>Email Id</th>
                                                <th>Mobile Number</th>
                                                <th> SMS Link Sent</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData && tableData.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data.vid}</td>
                                                    <td>{data.name}</td>
                                                    <td>{data.leader?.name}</td>
                                                    <td>{data.email_id}</td>
                                                    <td>{data.mobile_no}</td>
                                                    
                                                    <td>{data.sms_sent ===0 ?<img  src={cross} alt="loginImage"  title='SMS NOT SENT' />: <img  src={Tick} alt="loginImage"  title='SMS SENT' />}</td>
                                                    <td>
                                                    <button onClick={(e) => handleShowSms(e, index, data)}  className='btn '>
                                                              <img  src={smsimage} alt="loginImage"  title='SEND SMS' />
                                                        </button>
                                                        <button onClick={(e) => handleShowEdit(e, index, data)} style={{ color: "#53047b" }} className='btn '>
                                                            <i className='anticon anticon-edit'></i>
                                                        </button>


                                                        <button onClick={() => handleShowDelete(index)} className='btn'>
                                                            <i className='anticon anticon-delete' style={{ color: "#53047b" }}></i>
                                                        </button>

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                   </div>
                                 







                                    <Modal show={showEdit} onHide={handleCloseEdit}>
                                        <Modal.Header >
                                            <Modal.Title>Edit Volunteer</Modal.Title>
                                            <FaXmark style={{ cursor: 'pointer', marginTop: "2%" }} onClick={handleCloseEdit} />
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form >
                                             <div className='mb-3'>
                                                    <label className='form-label'>Select BV Leader</label>
                                                    <Select
                                                        options={options}
                                                        value={options.find(option => option.value === formData?.leader_id) || null}
                                                        onChange={(selectedOption) => 
                                                        setFormData((prevData) => ({
                                                            ...prevData,
                                                            leader_id: selectedOption ? selectedOption.value : null
                                                        }))}
                                                        placeholder="Select a leader"
                                                    />
                                                    {formErrors.leader_id && <div className='is-invalid'>{formErrors.leader_id}</div>}
                                                </div>
                                                <div className='mb-3'>
                                                    <label className='form-label'>Name</label>
                                                    <input
                                                        type='text'
                                                        className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                                                        name='name'
                                                        value={formData?.name}
                                                        onChange={handleInputChange}
                                                    />
                                                    {console.log(formErrors)}
                                                    {formErrors.name && <div className='invalid-feedback'>{formErrors.name}</div>}
                                                </div>
                                                <div className='mb-3'>
                                                    <label className='form-label'>Email Id</label>
                                                    <input
                                                        type='email'
                                                        className={`form-control ${formErrors?.email_id ? 'is-invalid' : ''}`}
                                                        name='email_id'
                                                        value={formData?.email_id}
                                                        onChange={handleInputChange}
                                                        aria-describedby='emailHelp'
                                                    />
                                                        {formErrors.email_id && <div className='invalid-feedback'>{formErrors.email_id}</div>}

                                                </div>
                                                <div className='mb-3'>
                                                    <label className='form-label'>Mobile Number</label>
                                                    <input
                                                        type='text'
                                                        className={`form-control ${formErrors?.mobile_no ? 'is-invalid' : ''}`}
                                                        name='mobile_no'
                                                        value={formData?.mobile_no}
                                                        onChange={handleInputChange}
                                                        aria-describedby='emailHelp'
                                                    />
                                            {formErrors.mobile_no && <div className='invalid-feedback'>{formErrors.mobile_no}</div>}

                                                </div>

                                               

                                                <div>

                                                    <button className='btn btn-md dynamic-color' style={{ color: "white" }} onClick={VolunteerUpdate} >
                                                        Submit
                                                    </button>
                                                    <button className="btn btn-md" style={{ backgroundColor: "#8c8281", marginLeft: "2%", color: "white" }} onClick={handleCloseEdit}  >
                                                        Close
                                                    </button>
                                                </div>
                                            </form>
                                        </Modal.Body>
                                    </Modal>

                                    {/* Delete Confirmation Modal */}
                                    <Modal show={showDelete} onHide={handleCloseDelete}>
                                        <Modal.Header>
                                            <Modal.Title>Delete Confirmation</Modal.Title>
                                            <FaXmark style={{ cursor: 'pointer', marginTop: "2%" }} onClick={handleCloseDelete} />
                                        </Modal.Header>
                                        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                                        <Modal.Footer>
                                            <button onClick={VolunteerDelete} className='dynamic-color btn btn-md text-white'>
                                                Yes
                                            </button>
                                            <button className="btn btn-md " style={{ backgroundColor: "darkgrey", color: "white" }} onClick={handleCloseDelete}>
                                                No
                                            </button>

                                        </Modal.Footer>

                                    </Modal>
                                    <Modal show={showSms} onHide={handleCloseSms}>
                                        <Modal.Header>
                                            <Modal.Title>Send SMS</Modal.Title>
                                            <FaXmark style={{ cursor: 'pointer', marginTop: "2%" }} onClick={handleCloseSms} />
                                        </Modal.Header>
                                        <Modal.Body>Are you sure you want to Send SMS Link To Volunteer?</Modal.Body>
                                        <Modal.Footer>
                                            <button onClick={sendSms} className='dynamic-color btn btn-md text-white'>
                                                Yes
                                            </button>
                                            <button className="btn btn-md " style={{ backgroundColor: "darkgrey", color: "white" }} onClick={handleCloseSms}>
                                                No
                                            </button>

                                        </Modal.Footer>

                                    </Modal>
                                    <div>
                                        <ul className="pagination" style={{ marginLeft: "70%" }}>
                                            <li className="page-item">
                                                <a href="#" className="page-link" onClick={prePage}>
                                                    Prev
                                                </a>
                                            </li>
                                            {numbers.map((n, i) => (
                                                <li className={`page-item ${pageno === n ? "active" : ""}`} key={i}>
                                                    <a href="#" className="page-link " onClick={() => changePage(n)}>
                                                        {n}
                                                    </a>
                                                </li>
                                            ))}
                                            <li className="page-item">
                                                <a href="#" className="page-link" onClick={nextPage}>
                                                    Next
                                                </a>
                                            </li>
                                        </ul>
                                        {loading && (
                                            <div className="loading-spinner-container"  >
                                                <RotatingLines
                                                    strokeColor="grey"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="70"
                                                    visible={true}
                                                />
                                            </div>
                                        )}
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <style jsx>{`
                .loading-spinner-container {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                   
                    z-index: 9999;
                }
            `}</style>
        </div>
    );
};

export default ManageVolunteers;
