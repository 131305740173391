import React, { useState, useEffect } from 'react';
import Sidebar from '../Components/Sidebar';
import Navbar from '../Components/Navbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from '../interceptor';
import urls from '../endpoint';
import { Row, Col } from 'react-bootstrap'
import { FaDownload, FaXmark } from "react-icons/fa6";
import { FaRotate } from 'react-icons/fa6';
import { RotatingLines } from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VolunteerSummaryReport = () => {

    const [isClicked, setIsClicked] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageno, setPageNo] = useState(1);
    const [loading, setLoading] = useState(false);
    const [sevasData, setSevasData] = useState([])
    const MAX_PAGES_DISPLAYED = 5;
    const [totalpages, setTotalPages] = useState([]);
    const currentPage = totalpages.current_page;
    const npages = totalpages.last_page ? totalpages.last_page : 0;
    const srNo = currentPage * 10 - 10 + 1;
    const startPage = Math.max(1, pageno - Math.floor(MAX_PAGES_DISPLAYED / 2));
    const endPage = Math.min(npages, startPage + MAX_PAGES_DISPLAYED - 1);
    const numbers = [...Array(endPage - startPage + 1).keys()].map(
        (i) => startPage + i
    );
    const currentDate = new Date().toISOString().split('T')[0];
    const [VolunteerDetailsForTable, setVolunteerDetailsForTable] = useState()
    const [VolunteerDataDateWise, setVolunteerDataDateWise] = useState({
        start_date: currentDate,
        end_date: currentDate
    });
    const [showModal, setShowModal] = useState(false);

    const handleShow = (data) => {
        setShowModal(true)
        
    };
    const handleClose = () => setShowModal(false);
    useEffect(() => {
        getVolunteerReportData()
    }, [VolunteerDataDateWise])

    useEffect(() => {
        if ((pageno, rowsPerPage)) {
            getVolunteerReportData()
        }

    }, [pageno, rowsPerPage])


    const dateHandler = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setVolunteerDataDateWise((prevData) => ({
            ...prevData,
            [name]: value

        }))


    }

    const getVolunteerReportDataReload = () => {
        setLoading(true)
        setIsClicked(true);
        setVolunteerDataDateWise({   
            start_date: currentDate,
            end_date: currentDate})
        axios.post(urls.volunteer_summary_report, {
          
                start_date: currentDate,
                end_date: currentDate,
                page: pageno,
                paginate: 1
        

        })
            .then((resp) => {
                const respdata = resp.data;
                console.log(respdata.data);

                setVolunteerDetailsForTable(resp.data.data)
                setTotalPages(respdata.data);
                setLoading(false);
                setIsClicked(false);
            })
            .catch((error) => {
                console.error('Error fetching Volunteer:', error);
                setLoading(false);
                setIsClicked(false);
            })
    }
    const getVolunteerReportData= () => {
        setLoading(true)
        axios.post(urls.volunteer_summary_report, {
          
                start_date: VolunteerDataDateWise.start_date,
                end_date: VolunteerDataDateWise.end_date,
                page: pageno,
                paginate: 1
          
        })
            .then((resp) => {
                const respdata = resp.data;
                console.log(respdata.data);

                setVolunteerDetailsForTable(resp.data.data)
                setTotalPages(respdata.data);
                setLoading(false);
            })
            .catch((error) => {
                setVolunteerDetailsForTable([])
                console.error('Error fetching Volunteer:', error);
                setLoading(false);

            })
    }
    const getVolunteerReportDataDownload= () => {
        setLoading(true)
        axios.post(urls.volunteer_summary_report, {
          
                start_date: VolunteerDataDateWise.start_date,
                end_date: VolunteerDataDateWise.end_date,
                response_type: "xlsx"
          
        })
            .then((resp) => {
                
                const url = resp.data.data.link;
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "report.xls");
                document.body.appendChild(link);
                link.click();
                setLoading(false);
                
            })
            .catch((error) => {
               
                console.error('Error fetching Volunteer:', error);
                setLoading(false);

            })
    }




    const prePage = () => {
        if (pageno !== 1) {
            setPageNo(pageno - 1);
        }
    }
    const changePage = (id) => {
        console.log(id);
        setPageNo(id);
    }

    const nextPage = () => {
        console.log("pageno", pageno)
        console.log("npgaes", npages)
        if (pageno !== npages) {
            setPageNo(pageno + 1);
        }
    }


    return (
        <div className='app'>
            <div className='layout'>
                <Navbar />
                <Sidebar />
                <div className='page-container dynamic-bgcolor' >


                    <div>
                        <div className='main-content'>
                            <div style={{ marginTop: "-2%" }}>
                                <h3 className='text-center'>Volunteer Summary Report</h3>

                                <ToastContainer position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    theme="light" />
                                <Row className='mt-5' style={{ marginTop: "3%" }}>
                                    <Col md={2} style={{ marginTop: "1%" }}>
                                        <label>Start Date</label>
                                        <input type="date"
                                            className={`form-control`}
                                            onChange={dateHandler}
                                            name='start_date'
                                            value={VolunteerDataDateWise.start_date}

                                        />
                                    </Col>

                                    <Col md={2} style={{ marginTop: "1%" }}>
                                        <label>End Date</label>
                                        <input type="date"
                                            className={`form-control`}
                                            onChange={dateHandler}
                                            name='end_date'
                                            value={VolunteerDataDateWise.end_date}
                                        />
                                    </Col>
                                    <Col style={{ marginLeft: "50%",marginTop:"3%" }}>
                                    <FaDownload    onClick={getVolunteerReportDataDownload} title='Download Report'  size={25} />
                                 
                                    
                                    </Col>
                                    <Col style={{ marginTop:"3%" }}>
                                    <FaRotate onClick={getVolunteerReportDataReload} title='Refresh Data'  size={25} />
                                 
                                    
                                    </Col>
                                </Row>
                               {VolunteerDetailsForTable && VolunteerDetailsForTable.length > 0 ? <div style={{ marginTop: "2%", overflowX:"auto",overflowY:"hidden" }} >



                                    <table className='table mt-2'>
                                        <thead>
                                            <tr>
                                                <th>Volunteer Name</th>
                                                <th>Amount Collected</th>
                                                <th>No.of Donations</th>
                                            
                                              



                                            </tr>
                                        </thead>
                                        {console.log(VolunteerDetailsForTable)}
                                        <tbody>
                                            {VolunteerDetailsForTable && VolunteerDetailsForTable.length > 0 && VolunteerDetailsForTable.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data.volunteer_name}</td>
                                                    <td>{data.amount_collected}</td>
                                                    <td>{data.donations_count}</td>
                                                  
                                                    

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>







                                    <div>
                                        <ul className="pagination" style={{ marginLeft: "70%" }}>
                                            <li className="page-item">
                                                <a href="#" className="page-link" onClick={prePage}>
                                                    Prev
                                                </a>
                                            </li>
                                            {numbers.map((n, i) => (
                                                <li className={`page-item ${pageno === n ? "active" : ""}`} key={i}>
                                                    <a href="#" className="page-link " onClick={() => changePage(n)}>
                                                        {n}
                                                    </a>
                                                </li>
                                            ))}
                                            <li className="page-item">
                                                <a href="#" className="page-link" onClick={nextPage}>
                                                    Next
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    
                                    <Modal show={showModal} onHide={handleClose} centered>
                                        <Modal.Header >
                                            <Modal.Title>Sevas Offered</Modal.Title>
                                            <FaXmark onClick={handleClose} />
                                        </Modal.Header>
                                        <Modal.Body>
                                            <table>
                                                <thead>
                                                    <th style={{paddingBottom:"5%"}}>Seva Name  </th>
                                                    <th style={{paddingLeft:"5%",paddingBottom:"5%"}}>Amount</th>
                                                </thead>
                                                <tbody>
                                                    {sevasData && sevasData.length > 0 ? (
                                                        sevasData.map((data, index) => (
                                                            <tr>
 <td>
                                                               {data.seva_name}
                                                              
                                                            </td>
                                                            <td style={{paddingLeft:"5%"}}>
                                                                 Rs.{data.amount}
                                                            </td>
                                                            </tr>
                                                           
                                                        ))
                                                    ) : (
                                                        <p>No sevas available</p>
                                                    )}
                                                </tbody>
                                            </table>


                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="none" style={{ backgroundColor: "grey", color: 'white' }} onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>:
                                <div style={{justifyContent:'center',alignItems:'center',display:'flex',marginTop:"13%"}}>
                     <h4>No Data Available</h4>
                </div>}
                                
                            </div>

                        </div>
                        {loading && (
                                        <div className="loading-spinner-container" >
                                            <RotatingLines
                                                strokeColor="grey"
                                                strokeWidth="5"
                                                animationDuration="0.75"
                                                width="70"
                                                visible={true}
                                            />
                                        </div>
                                    )}
                    </div>
                   
                </div>
             
            </div>
            <style jsx>{`
                .loading-spinner-container {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                   
                    z-index: 9999;
                }
            `}</style>

        </div>
    );
};

export default VolunteerSummaryReport;
