import React, { useEffect } from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import { Outlet } from 'react-router-dom'


const Layout = () => {

  return (
    <div className='app'>
        <div className='layout'>
            <main>
            <Navbar/>
            <Sidebar/>
            <Outlet/>
            </main>
            
        </div>
    </div>
  )
}

export default Layout;