import React, { useState, useEffect } from 'react';
import Sidebar from '../Components/Sidebar';
import Navbar from '../Components/Navbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from '../interceptor';
import urls from '../endpoint';


import { Row, Col } from 'react-bootstrap'
import { FaXmark } from "react-icons/fa6";
import { RotatingLines } from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaSearch } from 'react-icons/fa'; 
const Devotees = () => {


    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageno, setPageNo] = useState(1);
    const [loading, setLoading] = useState(false);
    const [searchData,setSearchData]=useState()
    const MAX_PAGES_DISPLAYED = 5;
    const [totalpages, setTotalPages] = useState([]);
    const currentPage = totalpages.current_page;
    const npages = totalpages.last_page ? totalpages.last_page : 0;
    const srNo = currentPage * 10 - 10 + 1;
    const startPage = Math.max(1, pageno - Math.floor(MAX_PAGES_DISPLAYED / 2));
    const endPage = Math.min(npages, startPage + MAX_PAGES_DISPLAYED - 1);
    const numbers = [...Array(endPage - startPage + 1).keys()].map(
        (i) => startPage + i
    );
    const currentDate = new Date().toISOString().split('T')[0];
    const [DevoteesDetailsForTable, setDevoteesDetailsForTable] = useState()
    const [DevoteesDataDateWise, setDevoteesDataDateWise] = useState({
        start_date: currentDate,
        end_date: currentDate
    });
    useEffect(() => {
        getDevotees()
    }, [DevoteesDataDateWise])
    useEffect(() => {
        if ((pageno, rowsPerPage)) {
            getDevotees()
        }

    }, [pageno, rowsPerPage])


    const dateHandler = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setDevoteesDataDateWise((prevData) => ({
            ...prevData,
            [name]: value

        }))


    }


    const getDevotees = () => {
        setLoading(true)
        axios.get(urls.get_devotees, {
            params: {
                
                page: pageno,
                paginate: 1
            }
        })
            .then((resp) => {
                const respdata = resp.data;
                console.log(respdata.data);

                setDevoteesDetailsForTable(resp.data.data.data)
                setTotalPages(respdata.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching Devotees:', error);
                setLoading(false);

            })
    }





    const prePage = () => {
        if (pageno !== 1) {
            setPageNo(pageno - 1);
        }
    }
    const changePage = (id) => {
        console.log(id);
        setPageNo(id);
    }

    const nextPage = () => {
        console.log("pageno", pageno)
        console.log("npgaes", npages)
        if (pageno !== npages) {
            setPageNo(pageno + 1);
        }
    }
    const  devoteeSearch = () => {
        console.log(searchData)
        if (searchData) {
            fetchDevoteeByMobileNumber(searchData);
        } else {
            setDevoteesDetailsForTable(DevoteesDetailsForTable)
       
        }
      };
      const fetchDevoteeByMobileNumber= async (searchData) => {
        try {
          const response = await axios.get(urls.get_devotees, {params:{query: searchData}});
          const devoteeData = response.data.data;
          console.log(devoteeData)
          setDevoteesDetailsForTable(devoteeData)
        } catch (error) {
                console.log(error)
        }
      };

    return (
        <div className='app'>
            <div className='layout'>
                <Navbar />
                <Sidebar />
                <div className='page-container dynamic-bgcolor' >


                    <div>
                        <div className='main-content'>
                            <div style={{ marginTop: "-2%" }}>
                                <h3 className='text-center'>Devotees</h3>

                                <ToastContainer position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    theme="light" />
                                <Row>
                                        <Col md={3} style={{ marginLeft: "74%", marginTop: "3%" }}>
                                            <form >

                                                <div className="input-group">

                                                    <input
                                                        type="text"
                                                        placeholder="Enter Mobile Number"
                                                        className='form-control '
                                                      onChange={(e) => setSearchData(e.target.value)}
                                                      onKeyUp={devoteeSearch}
                                                    />

                                                    <div className="input-group-append">
                                                        <button className='dynamic-color btn btn-md' style={{ color: "white" }}><FaSearch /> </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </Col>


                                    </Row>
                                <div style={{ marginTop: "2%" }}>



                                    <table className='table mt-2'>
                                        <thead>
                                            <tr>
                                            
                                                <th>Devotee Name</th>
                                                <th>Devotee Mobile No</th>
                                            
                                                <th>Devotee Email Id</th>
                                                <th>Devotee DOB</th>
                                                <th>Total Amount Donated Till Now <br/>(in Rupees)</th>
                                              




                                            </tr>
                                        </thead>
                                        {console.log(DevoteesDetailsForTable)}
                                        <tbody>
                                            {DevoteesDetailsForTable && DevoteesDetailsForTable.length > 0 && DevoteesDetailsForTable.map((data, index) => (
                                                <tr key={index}>
                                                   
                                                    <td>{data.name}</td>
                                                    <td>{data.mobile_no}</td>
                                                    <td>{data.email_id}</td>
                                                    <td>{data.dob}</td>
                                                    <td> {data.total_amount_donated}</td>
                                    

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>







                                    <div>
                                        <ul className="pagination" style={{ marginLeft: "70%" }}>
                                            <li className="page-item">
                                                <a href="#" className="page-link" onClick={prePage}>
                                                    Prev
                                                </a>
                                            </li>
                                            {numbers.map((n, i) => (
                                                <li className={`page-item ${pageno === n ? "active" : ""}`} key={i}>
                                                    <a href="#" className="page-link " onClick={() => changePage(n)}>
                                                        {n}
                                                    </a>
                                                </li>
                                            ))}
                                            <li className="page-item">
                                                <a href="#" className="page-link" onClick={nextPage}>
                                                    Next
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    {loading && (
                                        <div className="loading-spinner-container" >
                                            <RotatingLines
                                                strokeColor="grey"
                                                strokeWidth="5"
                                                animationDuration="0.75"
                                                width="70"
                                                visible={true}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <style jsx>{`
                .loading-spinner-container {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                   
                    z-index: 9999;
                }
            `}</style>
        </div>
    );
};

export default Devotees;
