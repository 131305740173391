import axios from "axios";
//import router from './router/router';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  let token = localStorage.getItem("token");
  token = JSON.parse(token);
  config.headers["Authorization"] = 'Bearer ' + token;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    // if (response.status === 200 || response.status === 201) {
    //   return Promise.resolve(response);
    // } else {
    //   return Promise.reject(response);
    // }
    return response;
  },
  (error) => {
    if (error.response.status == 401) {
      window.location.href = "/";
     //router.push("/login")
      return Promise.reject(error)
    }

    if(error.response.status == 422){
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
);

export default axiosInstance;
