import React, { useEffect, useState } from "react";
import endpoints from "../../endpoint";
import axios from "../../interceptor";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

import InputGroup from "react-bootstrap/InputGroup";

function AclCreateForm() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [rolename, setRoleName] = useState("");
	const [permissionlist, setPermissionList] = useState([]);
	const [selectedPermission, setSelectedPermission] = useState([]);

	async function getPermissionList() {
		try {
			const res = await axios.get(endpoints.permissionslistinacl);
			if (res.status === 200) {
				let data = res.data.data;
				setPermissionList(data);
			} else {
				console.log("Error: Unable to fetch Dashboard data.");
			}
		} catch (error) {
			console.log(
				"Error occurred while making the HTTP request:",
				error.message
			);
		}
	}

	const handleCheckboxChange = (id) => {
		const updatedPermissions = [...selectedPermission];
		const index = updatedPermissions.indexOf(id);
		if (index === -1) {
			updatedPermissions.push(id);
		} else {
			updatedPermissions.splice(index, 1);
		}
		setSelectedPermission(updatedPermissions);
	};

	const ToggleButton = ({ isChecked, permissionId, onToggle }) => {
		const handleClick = () => {
			onToggle(permissionId); // Call the parent component's function with the permissionId
		};

		return (
			<div
				className={`toggle-button ${isChecked ? "on" : "off"}`}
				onClick={handleClick}
			>
				<div className="inner-circle"></div>
			</div>
		);
	};

	async function handleSubmit() {
		setLoading(true);
		try {
			let payload = {
				roleName: rolename,
				permissions: selectedPermission.map((permission) =>
					permission.toString()
				),
			};

			const res = await axios.post(endpoints.aclCreate, payload);
			if (res.status === 200) {
				setSelectedPermission([]);
				navigate("/access-control-lists");
				setLoading(false);
			} else {
				console.log("Error: Unable to fetch API");
			}
		} catch (error) {
			setLoading(false);
			console.log(
				"Error occurred while making the HTTP request:",
				error.message
			);
		}
	}

	useEffect(() => {
		getPermissionList();
	}, []);

	return (
		<div className="app">
			<div className="layout">
				{/* <Navbar />
				<Sidebar /> */}
				<div className="page-container dynamic-bgcolor">
					<div className="main-content">
						<div>
							<div>
								<h2
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									Create Access Control List
								</h2>
								<div>
									<button className="btn dynamic-color">
										<Link
											to={"/access-control-lists"}
											style={{ color: "white" }}
										>
											Back
										</Link>
									</button>
								</div>
								<form className="aclcreateform">
									<div>
										<span>Role Name</span>
										<div style={{ width: "70%" }}>
											<Form.Control
												onChange={(e) => {
													setRoleName(e.target.value);
												}}
												placeholder="Enter role name"
												type="text"
											/>
										</div>
									</div>
									<div>
										<span>Permissions:</span>
									</div>
									<div style={{ marginLeft: "5%" }}>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												width: "100%",
											}}
										>
											{permissionlist.map((list, index) => {
												const isChecked = selectedPermission.includes(list.id);
												return (
													<Row
														style={{
															width: "100%",
															display: "flex",
															justifyContent: "center",
															marginTop: "vh",
														}}
														className="align-items-center"
													>
														<Col style={{ padding: "0.5%" }}>
															<label className="form-check-label">
																{list.display_name}
															</label>
														</Col>
														<Col style={{ padding: "0.5%" }}>
															<div>
																<ToggleButton
																	isChecked={isChecked}
																	permissionId={list.id}
																	onToggle={handleCheckboxChange}
																/>
															</div>
														</Col>
													</Row>
												);
											})}
										</div>
									</div>
								</form>
								{loading ? (
									<button
										type="button"
										className="btn dynamic-color"
										style={{
											color: "white",
											marginLeft: "30%",
											marginTop: "5vh",
										}}
										disabled
										onClick={() => {
											handleSubmit();
										}}
									>
										Submit &nbsp;
										<i class="fas fa-spinner fa-spin"></i>
									</button>
								) : (
									<button
										type="button"
										className="btn dynamic-color"
										style={{
											color: "white",
											marginLeft: "35%",
											marginTop: "5vh",
										}}
										onClick={() => {
											handleSubmit();
										}}
									>
										Submit
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AclCreateForm;
