import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaShieldAlt, FaUser, FaUsers } from "react-icons/fa";
import { FiAirplay, FiDatabase, FiGift, FiShoppingCart, FiUsers,FiFileText} from "react-icons/fi";
import { TbDashboard } from "react-icons/tb";
import { FaHotTubPerson } from "react-icons/fa6";
import reports from '../assets/bar-chart.png'
const Sidebar = () => {
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [activeLink, setActiveLink] = useState("/");
	// const [showDashboard, setShowDashboard] = useState(true);
	const toggleDropdown = () => {
		setDropdownVisible(!dropdownVisible);
	};

	// let merchant_details = localStorage.getItem("merchant_details");
	// let merchant_details_after_parsing = JSON.parse(merchant_details);
	// let storedPermissionList = merchant_details_after_parsing[0].permissions;

	// const location = useLocation();

	// useEffect(() => {
	// 	setActiveLink(location.pathname);
	// }, [location.pathname]);

	return (
		<div
			className="side-nav "
			style={{ marginTop: "-2%", backgroundColor: "#f5f5f5" }}
		>
			<div className="side-nav-inner ">
				<ul className="side-nav-menu scrollable mt-4" style={{marginLeft:"7%"}}>
					<li
						style={{ marginTop: "25%" }}
						className={` ${activeLink === "/dashboard" ? "active_bg " : ""}`}
					>
						<Link to={"/dashboard"}>
							<span className="icon-holder">
								<TbDashboard className="dynamic-text-color"  size={23}/>
							</span>
							<span className="title">Dashboard</span>
						</Link>
					</li>
					<li
						style={{ marginTop: "2%" }}
						// className={` ${activeLink === "/dashboard" ? "active_bg " : ""}`}
					>
						<Link to={"/manageleaders"}>
							<span className="icon-holder">
								<FaUsers className="dynamic-text-color" size={23}/>
							</span>
							<span className="title">Manage BV Leaders</span>
						</Link>
					</li>
					<li
						style={{ marginTop: "2%" }}
						// className={` ${activeLink === "/dashboard" ? "active_bg " : ""}`}
					>
						<Link to={"/managevolunteers"}>
							<span className="icon-holder">
								<FaUser className="dynamic-text-color" />
							</span>
							<span className="title">Manage Volunteers</span>
						</Link>
					</li>
					{/* {storedPermissionList.some(
						(permission) =>
							permission.display_name === "CAN_ACCESS_ALL" ||
							permission.display_name === "CAN_VIEW_ACL"
					) && (
						<li
							className={` ${
								activeLink === "/access-control-lists" ? "active_bg " : ""
							}`}
						>
							<Link to={"/access-control-lists"}>
								<span className="icon-holder">
									<FaShieldAlt style={{ color: "#176b87" }} />
								</span>
								<span className="title">Access Control List</span>
							</Link>
						</li>
					)} */}

					<li className={` ${activeLink === "/donations" ? "active_bg " : ""}`}>
						<Link to={"/donations"}>
							<span className="icon-holder">
								<FiGift className="dynamic-text-color" />
							</span>
							<span className="title">Donations</span>
						</Link>
					</li>
					<li className={` ${activeLink === "/devotees" ? "active_bg " : ""}`}>
						<Link to={"/devotees"}>
							<span className="icon-holder">
								<FiUsers className="dynamic-text-color" />
							</span>
							<span className="title">Devotees</span>
						</Link>
					</li>
					

					 <li className="nav-item dropdown mt-1 ">
						<Link
							to="#"
							className={`dropdown-toggle ${dropdownVisible ? "active" : ""}`}
							onClick={toggleDropdown}
						>
							<span className="icon-holder">
							{/* <FiFileText className="dynamic-text-color" /> */}
							<img src={reports} style={{height:"80%",width:"80%"}} />
							</span>
							<span className="title">Reports</span>
							<span className="arrow">
								<i
									className={`arrow-icon ${dropdownVisible ? "open" : ""}`}
									onClick={toggleDropdown}
								></i>
							</span>
						</Link>
						<ul className={`dropdown-menu ${dropdownVisible ? "show" : ""}`}>
						<li className={` ${activeLink === "/volunteersummaryreport" ? "active_bg " : ""}`}>
						<Link to={"/volunteersummaryreport"}>
							
							<span className="title">Volunteer Summary Report</span>
						</Link>
						</li>
						<li className={` ${activeLink === "/volunteerreport" ? "active_bg " : ""}`}>
						<Link to={"/volunteerreport"}>
							
							<span className="title">Volunteer Report By Date Wise</span>
						</Link>
					</li>
					<li className={` ${activeLink === "/donationsreport" ? "active_bg " : ""}`}>
						<Link to={"/donationsreport"}>
							
							<span className="title">Donations Report</span>
						</Link>
					</li>
					<li className={` ${activeLink === "/revenuereport" ? "active_bg " : ""}`}>
						<Link to={"/revenuereport"}>
							
							<span className="title">Revenue Report</span>
						</Link>
					</li>
					<li className={` ${activeLink === "/topfivevolunteerreport" ? "active_bg " : ""}`}>
						<Link to={"/topfivevolunteerreport"}>
							
							<span className="title">Top 5 Volunteers Report</span>
						</Link>
					</li>
					<li className={` ${activeLink === "/topfivesevasoptedreport" ? "active_bg " : ""}`}>
						<Link to={"/topfivesevasoptedreport"}>
							
							<span className="title">Top 5 Sevas Opted Report</span>
						</Link>
					</li>
						</ul>
					</li> 
					{/* <li className="nav-item dropdown mt-1 ">
						<Link
							to="#"
							className={`dropdown-toggle ${dropdownVisible ? "active" : ""}`}
							onClick={toggleDropdown}
						>
							<span className="icon-holder">
								<i
									className="anticon anticon-appstore"
									style={{ color: "#176b87" }}
								></i>
							</span>
							<span className="title">Customer Master</span>
							<span className="arrow">
								<i
									className={`arrow-icon ${dropdownVisible ? "open" : ""}`}
								></i>
							</span>
						</Link>
						<ul className={`dropdown-menu ${dropdownVisible ? "show" : ""}`}>
							<li>
								<Link to={"#"}>Customers</Link>
							</li>
							<li>
								<Link to={"#"}>Subscription List</Link>
							</li>
							<li>
								<Link to={"#"}>Combined Import</Link>
							</li>
						</ul>
					</li> */}
					{/* <li className="nav-item dropdown mt-1 ">
						<Link
							to="#"
							className={`dropdown-toggle ${dropdownVisible ? "active" : ""}`}
							onClick={toggleDropdown}
						>
							<span className="icon-holder">
								<i
									className="anticon anticon-appstore"
									style={{ color: "#176b87" }}
								></i>
							</span>
							<span className="title">Operations</span>
							<span className="arrow">
								<i
									className={`arrow-icon ${dropdownVisible ? "open" : ""}`}
								></i>
							</span>
						</Link>
						<ul className={`dropdown-menu ${dropdownVisible ? "show" : ""}`}>
							<li>
								<Link to={"#"}>Jobs</Link>
							</li>
							<li>
								<Link to={"#"}>Mapping</Link>
							</li>
						</ul>
					</li> */}
					{/* <li>
						<Link to={"#"}>
							<span className="icon-holder">
								<i
									className="anticon anticon-dashboard"
									style={{ color: "#176b87" }}
								></i>
							</span>
							<span className="title">Invoices</span>
						</Link>
					</li> */}
					{/* <li>
						<Link to={"#"}>
							<span className="icon-holder">
								<i
									className="anticon anticon-dashboard"
									style={{ color: "#176b87" }}
								></i>
							</span>
							<span className="title">Invoices</span>
						</Link>
					</li> */}
					{/* <li className="nav-item dropdown mt-1 ">
						<Link
							to="#"
							className={`dropdown-toggle ${dropdownVisible ? "active" : ""}`}
							onClick={toggleDropdown}
						>
							<span className="icon-holder">
								<i
									className="anticon anticon-appstore"
									style={{ color: "#176b87" }}
								></i>
							</span>
							<span className="title">Reports</span>
							<span className="arrow">
								<i
									className={`arrow-icon ${dropdownVisible ? "open" : ""}`}
								></i>
							</span>
						</Link>
						<ul className={`dropdown-menu ${dropdownVisible ? "show" : ""}`}>
							<li>
								<Link to={"#"}>Orders Report</Link>
							</li>
							<li>
								<Link to={"#"}>Item Sale Report</Link>
							</li>
							<li>
								<Link to={"#"}>Delivery Boy Report</Link>
							</li>
						</ul>
					</li> */}
					{/* <li>
						<Link to={"#"}>
							<span className="icon-holder">
								<i
									className="anticon anticon-dashboard"
									style={{ color: "#176b87" }}
								></i>
							</span>
							<span className="title">Manage Employees</span>
						</Link>
					</li> */}
					{/* <li className="nav-item dropdown mt-1 ">
						<Link
							to="#"
							className={`dropdown-toggle ${dropdownVisible ? "active" : ""}`}
							onClick={toggleDropdown}
						>
							<span className="icon-holder">
								<i
									className="anticon anticon-appstore"
									style={{ color: "#176b87" }}
								></i>
							</span>
							<span className="title">Setting</span>
							<span className="arrow">
								<i
									className={`arrow-icon ${dropdownVisible ? "open" : ""}`}
								></i>
							</span>
						</Link>
						<ul className={`dropdown-menu ${dropdownVisible ? "show" : ""}`}>
							<li>
								<Link to={"#"}>SKU Units</Link>
							</li>
							<li>
								<Link to={"#"}>Community and Area</Link>
							</li>
							<li>
								<Link to={"#"}>App Configuration</Link>
							</li>
							<li>
								<Link to={"#"}>Manage Payment Methods</Link>
							</li>
						</ul>
					</li> */}
				</ul>
			</div>
		</div>
	);
};

export default Sidebar;
