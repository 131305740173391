import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

import { MdEditNote } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import endpoints from "../../endpoint";
import axios from "../../interceptor";

const AccessControlList = () => {
	const navigate = useNavigate();
	const [aclRoleandPermissions, setAclRoleandPermissions] = useState([]);
	const [selectedIdForDelete, setSelectedIdForDelete] = useState();

	const [showdelete, setShowDelete] = useState(false);
	const handleCloseDelete = () => setShowDelete(false);
	const handleShowDelete = () => setShowDelete(true);

	let merchant_details = localStorage.getItem("merchant_details");
	let merchant_details_after_parsing = JSON.parse(merchant_details);
	let storedPermissionList = merchant_details_after_parsing[0].permissions;

	async function getAclGetAll() {
		try {
			const res = await axios.get(endpoints.aclGetAll);
			if (res.status === 200) {
				let data = res.data.data;
				setAclRoleandPermissions(data);
			} else {
				console.log("Error: Unable to fetch ACL data.");
			}
		} catch (error) {
			console.log(
				"Error occurred while making the HTTP request:",
				error.message
			);
		}
	}

	async function handleDeleteAclDetails() {
		try {
			let payload = {
				id: selectedIdForDelete,
			};
			const res = await axios.post(endpoints.aclDelete, payload);
			if (res.status === 200) {
				getAclGetAll();
				handleCloseDelete();
			} else {
				console.log("Error: Unable to fetch ACL Delete data.");
			}
		} catch (error) {
			console.log(
				"Error occurred while making the HTTP request:",
				error.message
			);
		}
	}

	const editAclDetails = (id) => {
		navigate(`/access-control-lists-edit/${id}`);
	};

	const handleDeleteAclIcon = (id) => {
		handleShowDelete();
		setSelectedIdForDelete(id);
	};

	useEffect(() => {
		getAclGetAll();
	}, []);

	return (
		<div className="app">
			<div className="layout">
				{/* <Navbar />
        <Sidebar /> */}
				<div className="page-container dynamic-bgcolor">
					<div className="main-content">
						<div style={{ marginTop: "-4%" }}>
							<div className="card-body">
								<h3 className="text-center">Access Control List</h3>

								{storedPermissionList.some(
									(permission) =>
										permission.display_name === "CAN_ACCESS_ALL" ||
										permission.display_name === "CAN_CREATE_ACL"
								) && (
									<button
										className="btn dynamic-color"
										style={{ color: "white" }}
									>
										<Link
											to={"/access-control-lists-create"}
											style={{ color: "white" }}
										>
											Create
										</Link>
									</button>
								)}
							</div>
						</div>
						<table className="table ml-2">
							<thead>
								<tr>
									<th>Role</th>
									<th>Permissions</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{aclRoleandPermissions.map((role) => (
									<tr key={role.id}>
										<td>{role.name}</td>
										<td>
											{role.RoleHasPermissions.map(
												(permission) => permission.Permission.display_name
											).join(", ")}
										</td>
										<td>
											{storedPermissionList.some(
												(permission) =>
													permission.display_name === "CAN_ACCESS_ALL" ||
													permission.display_name === "CAN_UPDATE_ACL"
											) && (
												<MdEditNote
													className="onMouseOver"
													onMouseOver={({ target }) =>
														(target.style.color = "#176b87")
													}
													onMouseOut={({ target }) =>
														(target.style.color = "grey")
													}
													onClick={() => editAclDetails(role.id)}
													size={25}
												/>
											)}
											{storedPermissionList.some(
												(permission) =>
													permission.display_name === "CAN_ACCESS_ALL" ||
													permission.display_name === "CAN_DELETE_ACL"
											) && (
												<MdDelete
													onMouseOut={({ target }) =>
														(target.style.color = "grey")
													}
													onMouseOver={({ target }) =>
														(target.style.color = "#176b87")
													}
													onClick={() => handleDeleteAclIcon(role.id)}
													size={25}
												/>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL FOR DELETING CATEGORIES */}
			<Modal size="md" centered show={showdelete} onHide={handleCloseDelete}>
				<Modal.Header>
					<Modal.Title>Delete Confirmation</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Are you sure you want to delete?</p>
				</Modal.Body>
				<Modal.Footer>
					<button
						onClick={() => {
							handleDeleteAclDetails();
						}}
						style={{ color: "white" }}
						className="btn dynamic-color"
					>
						Yes
					</button>
					<button
						style={{ color: "white", backgroundColor: "grey" }}
						className="btn dynamic-color"
						onClick={handleCloseDelete}
					>
						No
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default AccessControlList;
