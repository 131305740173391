import React from 'react';
import Sidebar from '../Components/Sidebar';
import Navbar from '../Components/Navbar';
import { useNavigate } from 'react-router-dom';
const PageNotFound = () => {
     const navigate=useNavigate()
       
    return (
        <div className='app'>
            <div className='layout'>
                <Navbar />
                <Sidebar />
                <div className='page-container dynamic-bgcolor'  >
                    <div style={{marginTop:"23%"}}>
                    <h2 className='text-center'><b>Page Not Found</b></h2>
                    <div className="d-flex justify-content-center " style={{marginTop:"3%"}}>
                         <button className='btn btn-md btn-rounded dynamic-color text-white ' onClick={()=>{navigate('/dashboard')}}>Go To Dashboard</button>
                         </div>
                    </div>
                        
                </div>

            </div>
        </div>
    )
}
export default PageNotFound