import React, { useEffect, useState } from "react";
import axios from "../interceptor";
import urls from "../endpoint";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Row, Col, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangePassword = () => {
	const navigate = useNavigate();
	const [passwords, setPasswords] = useState({
		old_password: "",
		password: "",
	});
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [formErrors, setFormErrors] = useState({});
	const updateNotify = () =>
		toast.success("Password Is Updated", {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		});
	const subHandler = (e) => {
		e.preventDefault();
		axios
			.post(urls.change_password, passwords)
			.then((resp) => {
				console.log(resp.data.data);
				navigate("/dashboard");
				updateNotify();
			})
			.catch((error) => {
				console.log(error.data);
				if (error.data.data) {
					setFormErrors(error.data.data);
				}
				if (error.data.data.length === 0) {
					setFormErrors(error.data);
				}
			});
	};

	const changePass = (e) => {
		const { name, value } = e.target;
		setFormErrors((prevErrors) => ({
			...prevErrors,
			[name]: null,
		}));
		setPasswords((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const togglePasswordVisibility = (field) => {
		if (field === "old_password") {
			setShowOldPassword((prevShowPassword) => !prevShowPassword);
		} else if (field === "password") {
			setShowNewPassword((prevShowPassword) => !prevShowPassword);
		}
	};
	const navigation = () => {
		navigate("/dashboard");
		console.log("navigated to dashboard");
	};

	return (
		<div className="app">
			<div className=" dynamic-bgcolor">
				<div>
					<Row style={{ marginLeft: "5%", marginTop: "5%" }}>
						<Col>
							<h4 className="text-center ">Change Password</h4>
						</Col>
					</Row>

					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						theme="light"
					/>
					<div className="row">
						<div className="col-md-12">
							<div
								className="card"
								style={{
									border: " 1px solid grey ",
									height: "70%",
									width: "30%",
									marginLeft: "39%",
									marginTop: "9%",
								}}
							>
								<div className="card-body">
									<form>
										<div className="form-group">
											<label htmlFor="old_password">Enter Old Password</label>
											<div className="input-group">
												<input
													type={showOldPassword ? "text" : "password"}
													className={`form-control ${
														formErrors.old_password ? "is-invalid" : ""
													}`}
													placeholder="Enter old password"
													onChange={(e) => changePass(e)}
													name="old_password"
												/>
												<div className="input-group-append">
													<span
														className="input-group-text"
														onClick={() =>
															togglePasswordVisibility("old_password")
														}
													>
														{showOldPassword ? <FaEyeSlash /> : <FaEye />}
													</span>
												</div>
											</div>
											{formErrors.old_password && (
												<div className="is-invalid">
													{formErrors.old_password}
												</div>
											)}
										</div>

										<div className="form-group mt-3">
											<label htmlFor="password">Enter New Password</label>
											<div className="input-group">
												<input
													type={showNewPassword ? "text" : "password"}
													className={`form-control ${
														formErrors.password ? "is-invalid" : ""
													}`}
													placeholder="Enter new password"
													onChange={(e) => changePass(e)}
													name="password"
												/>
												<div className="input-group-append">
													<span
														className="input-group-text"
														onClick={() => togglePasswordVisibility("password")}
													>
														{showNewPassword ? <FaEyeSlash /> : <FaEye />}
													</span>
												</div>
											</div>
											{formErrors.password && (
												<div className="is-invalid">{formErrors.password}</div>
											)}
										</div>
										{formErrors.message && (
											<div className="is-invalid">{formErrors.message}</div>
										)}
										<button
											className="btn btn-md dynamic-color text-white"
											variant="none"
											style={{ marginLeft: "38%", marginTop: "5%" }}
											onClick={subHandler}
										>
											Submit
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChangePassword;
