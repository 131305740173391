import React, { useEffect, useState } from "react";
import { Route,Routes, useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children}) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkUserToken = () => {
        const userToken = localStorage.getItem('token');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
            return navigate('/login');
        }else{
            setIsLoggedIn(true);
        }
       
    }

    useEffect(() => {
        checkUserToken();
    }, []);
    console.log("i m protectedroute")
    return isLoggedIn ? children: null;
}

export default ProtectedRoute;
