import React, { useState, useEffect } from 'react';
import Sidebar from '../Components/Sidebar';
import Navbar from '../Components/Navbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from '../interceptor';
import urls from '../endpoint';
import { Row, Col } from 'react-bootstrap'
import { FaXmark } from "react-icons/fa6";
import { FaRotate } from 'react-icons/fa6';
import { RotatingLines } from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
import { useSearchParams } from "react-router-dom";
import {FiSearch} from "react-icons/fi";

const Donations = () => {

    const [isClicked, setIsClicked] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageno, setPageNo] = useState(1);
    const [loading, setLoading] = useState(false);
    const [sevasData, setSevasData] = useState([])
    const MAX_PAGES_DISPLAYED = 5;
    const [totalpages, setTotalPages] = useState([]);
    const currentPage = totalpages.current_page;
    const npages = totalpages.last_page ? totalpages.last_page : 0;
    const srNo = currentPage * 10 - 10 + 1;
    const startPage = Math.max(1, pageno - Math.floor(MAX_PAGES_DISPLAYED / 2));
    const endPage = Math.min(npages, startPage + MAX_PAGES_DISPLAYED - 1);
    const [searchDevotee, setSearchDevotee] = useState(null);
    const [searchVolunteer, setSearchVolunteer] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const numbers = [...Array(endPage - startPage + 1).keys()].map(
        (i) => startPage + i
    );
    const currentDate = new Date().toISOString().split('T')[0];
    const [DonationsDetailsForTable, setDonationsDetailsForTable] = useState()
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [DonationsDataDateWise, setDonationsDataDateWise] = useState({
        start_date: currentDate,
        end_date: currentDate
    });



    const [showModal, setShowModal] = useState(false);
    const options = [
        { value: "all", label: "All" },
        { value: "paid", label: "Paid" },
        { value: "unpaid", label: "Unpaid" },
      ];

    const handleShow = (data) => {
        setShowModal(true)
        setSevasData(data.sevas)
    };
    const handleClose = () => setShowModal(false);
    useEffect(() => {
        getDonations()
    }, [DonationsDataDateWise])
    useEffect(() => {
        if ((pageno, rowsPerPage)) {
            getDonations()
        }

    }, [pageno, rowsPerPage])


    const dateHandler = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setDonationsDataDateWise((prevData) => ({
            ...prevData,
            [name]: value

        }))


    }

    const getDonationsReload = () => {
        window.location.reload()
        setLoading(true)
        setIsClicked(true);
        setDonationsDataDateWise({   
            start_date: currentDate,
            end_date: currentDate})
        axios.get(urls.get_donations, {
            params: {
                start_date: currentDate,
                end_date: currentDate,
                page: pageno,
                paginate: 1
            }

        })
            .then((resp) => {
                const respdata = resp.data;
                console.log(respdata.data);

                setDonationsDetailsForTable(resp.data.data.data)
                setTotalPages(respdata.data);
                setLoading(false);
                setIsClicked(false);
            })
            .catch((error) => {
                console.error('Error fetching donations:', error);
                setLoading(false);
                setIsClicked(false);
            })
    }

   

    const getDonations = () => {
        let params = {};
        if(searchDevotee && searchVolunteer && paymentStatus){
            // if(searchDevotee.length > 3 && searchVolunteer.length > 3){
                setSearchParams({
                    'start_date': DonationsDataDateWise.start_date,
                    'end_date': DonationsDataDateWise.end_date,
                    'page': pageno,
                    'paginate': 1,
                    'devotee_name': searchDevotee,
                    'volunteer_name': searchVolunteer,
                    'payment_status': paymentStatus
                })
                params = {
                    start_date: DonationsDataDateWise.start_date,
                    end_date: DonationsDataDateWise.end_date,
                    page: pageno,
                    paginate: 1,
                    devotee_name: searchDevotee,
                    volunteer_name: searchVolunteer,
                    payment_status: paymentStatus
                }
            // }else{
            //     changePage(1)
            //     params = {
            //         start_date: DonationsDataDateWise.start_date,
            //         end_date: DonationsDataDateWise.end_date,
            //         page: pageno,
            //         paginate: 1
            //     }
            // }
        }else if(searchDevotee && paymentStatus){
            setSearchParams({
                'start_date': DonationsDataDateWise.start_date,
                'end_date': DonationsDataDateWise.end_date,
                'page': pageno,
                'paginate': 1,
                'devotee_name': searchDevotee,
                'payment_status': paymentStatus
            })
            params = {
                start_date: DonationsDataDateWise.start_date,
                end_date: DonationsDataDateWise.end_date,
                page: pageno,
                paginate: 1,
                devotee_name: searchDevotee,
                payment_status: paymentStatus
            }
        }else if(searchVolunteer && paymentStatus){
            setSearchParams({
                'start_date': DonationsDataDateWise.start_date,
                'end_date': DonationsDataDateWise.end_date,
                'page': pageno,
                'paginate': 1,
                'volunteer_name': searchVolunteer,
                'payment_status': paymentStatus
            })
            params = {
                start_date: DonationsDataDateWise.start_date,
                end_date: DonationsDataDateWise.end_date,
                page: pageno,
                paginate: 1,
                volunteer_name: searchVolunteer,
                payment_status: paymentStatus
            }
        }else if(searchDevotee){
            
                setSearchParams({
                    'start_date': DonationsDataDateWise.start_date,
                    'end_date': DonationsDataDateWise.end_date,
                    'page': pageno,
                    'paginate': 1,
                    'devotee_name': searchDevotee
                })
                params = {
                    start_date: DonationsDataDateWise.start_date,
                    end_date: DonationsDataDateWise.end_date,
                    page: pageno,
                    paginate: 1,
                    devotee_name: searchDevotee
                }
            
        }else if(searchVolunteer){
           
                setSearchParams({
                    'start_date': DonationsDataDateWise.start_date,
                    'end_date': DonationsDataDateWise.end_date,
                    'page': pageno,
                    'paginate': 1,
                    'volunteer_name': searchVolunteer
                })
                params = {
                    start_date: DonationsDataDateWise.start_date,
                    end_date: DonationsDataDateWise.end_date,
                    page: pageno,
                    paginate: 1,
                    volunteer_name: searchVolunteer
                }
        }else if(paymentStatus){
            setSearchParams({
                'start_date': DonationsDataDateWise.start_date,
                'end_date': DonationsDataDateWise.end_date,
                'page': pageno,
                'paginate': 1,
                'payment_status': paymentStatus
            })
            params = {
                start_date: DonationsDataDateWise.start_date,
                end_date: DonationsDataDateWise.end_date,
                page: pageno,
                paginate: 1,
                payment_status: paymentStatus
            }
        }else{
            setSearchParams({
                'start_date': DonationsDataDateWise.start_date,
                'end_date': DonationsDataDateWise.end_date,
                'page': pageno,
                'paginate': 1
            })
            params = {
                start_date: DonationsDataDateWise.start_date,
                end_date: DonationsDataDateWise.end_date,
                page: pageno,
                paginate: 1
            }

        }
        setLoading(true)
        axios.get(urls.get_donations, {
            params: params
        })
            .then((resp) => {
                const respdata = resp.data;
                console.log(respdata.data);

                setDonationsDetailsForTable(resp.data.data.data)
                setTotalPages(respdata.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching donations:', error);
                setLoading(false);

            })
    }

    //   useEffect(() => {
    //     if(paymentStatus){
    //         getDonations()
    //     }
    //   }, [paymentStatus, DonationsDataDateWise]);

    const handleChange = (selectedOption) => {
        console.log("Selected option:", selectedOption);
        setPaymentStatus(selectedOption.value);
      };

    // const getDonationsByPaymentStatus = () => {
    //     if(paymentStatus){
    //       axios
    //       .get(urls.get_donations, {
    //         params: {
    //             start_date: DonationsDataDateWise.start_date,
    //             end_date: DonationsDataDateWise.end_date,
    //             page: pageno,
    //             paginate: 1,
    //             payment_status: paymentStatus
    //         }
    //     })
    //       .then((resp) => {
    //         const respdata = resp.data;
    //         console.log(respdata.data, "hello");
    
    //         setDonationsDetailsForTable(resp.data.data.data);
    //         setTotalPages(respdata.data);
    //         setLoading(false);
    //       })
    //       .catch((error) => {
    //         setDonationsDetailsForTable([]);
    //         console.error("Error fetching Donation:", error);
    //         setLoading(false);
    //       });
    //     }else{
    //         getDonations()
    //     }
    // }



    const prePage = () => {
        if (pageno !== 1) {
            setPageNo(pageno - 1);
        }
    }
    const changePage = (id) => {
        console.log(id);
        setPageNo(id);
        
    }

    const nextPage = () => {
        console.log("pageno", pageno)
        console.log("npgaes", npages)
        if (pageno !== npages) {
            setPageNo(pageno + 1);
            
        }
        
    }


    return (
        <div className='app'>
            <div className='layout'>
                <Navbar />
                <Sidebar />
                <div className='page-container dynamic-bgcolor' >


                    <div>
                        <div className='main-content'>
                            <div style={{ marginTop: "-2%" }}>
                                <h3 className='text-center'>Donations</h3>

                                <ToastContainer position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    theme="light" />
                                <Row className='mt-5' style={{ marginTop: "3%" }}>
                                    <Col md={2} style={{ marginTop: "1%" }}>
                                        <label>Start Date</label>
                                        <input type="date"
                                            className={`form-control`}
                                            onChange={dateHandler}
                                            name='start_date'
                                            value={DonationsDataDateWise.start_date}

                                        />
                                    </Col>

                                    <Col md={2} style={{ marginTop: "1%" }}>
                                        <label>End Date</label>
                                        <input type="date"
                                            className={`form-control`}
                                            onChange={dateHandler}
                                            name='end_date'
                                            value={DonationsDataDateWise.end_date}
                                        />
                                    </Col>
                                    <Col md={2} >
                                        <label>Search By Volunteer Name</label>
                                        <input
                                            type="text"
                                            placeholder="Search By Volunteer Name"
                                            className={`form-control`}
                                            name="search"
                                            onChange={(e) => setSearchVolunteer(e.target.value)}
                                            
                                        />
                                    </Col>
                                    <Col md={2} >
                                        <label>Search By Devotee Name</label>
                                        <input
                                            type="text"
                                            placeholder="Search By Devotee Name"
                                            className={`form-control`}
                                            name="search"
                                            onChange={(e) => setSearchDevotee(e.target.value)}
                                           
                                        />
                                    </Col>
                                    <Col md={2} style={{ marginTop: "1%" }}>
                                        <label>Select Payment Status</label>
                                        <Select
                                        options={options}
                                        onChange={handleChange}
                                        defaultValue={paymentStatus}
                                        placeholder="Select an option"
                                        />
                                     </Col>
                                     <Col md={1} style={{ marginTop: "3%" }}>
                                        <button
											type="button"
											className="btn dynamic-color login_button_testcolor"
											onClick={getDonations}
										>
                                            <span className="icon-holder">
                                                <FiSearch/>
                                            </span>
										</button>
                                     </Col>
                                     
                                    <Col style={{ marginTop:"3%" }}>
                                    <FaRotate  onClick={getDonationsReload} title='Refresh Data'  size={25} />
                                 
                                    
                                    </Col>
                                </Row>
                                <div style={{ marginTop: "2%", overflowX:"auto",overflowY:"hidden" }} >



                                    <table className='table mt-2'>
                                        <thead>
                                            <tr>
                                                <th>Donation No</th>
                                                <th>Devotee Name</th>
                                                <th> Mobile No</th>
                                                <th>Email Id</th>
                                            
                                                <th>Volunteer Name</th>
                                                <th>Festival Name</th>

                                                <th> Amount(in Rupees)</th>
                                                <th>Payment Id</th>
                                                <th>Payment Method</th>
                                                <th>Payment Status</th>
                                                <th>Sevas</th>



                                            </tr>
                                        </thead>
                                        {console.log(DonationsDetailsForTable)}
                                        <tbody>
                                            {DonationsDetailsForTable && DonationsDetailsForTable.length > 0 && DonationsDetailsForTable.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data.donation_no}</td>
                                                    <td>{data.devotee?.name}</td>
                                                    <td>{data.devotee?.mobile_no}</td>
                                                    <td>{data.devotee?.email_id}</td>
                                                  
                                                    <td>{data.volunteer?.name}</td>
                                                    <td >{data.festival_name}</td>
                                                    <td>{data.total_amount}</td>
                                                    <td>{data.payment_id}</td>
                                                    <td>{data.payment_method}</td>
                                                    <td>{<b>{data.payment_status}</b>}</td>
                                                    <th>
                                                        <button
                                                            className='dynamic-color btn btn-sm text-white'
                                                            onClick={() => handleShow(data)}
                                                        >View </button>
                                                    </th>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>







                                    <div>
                                        <ul className="pagination" style={{ marginLeft: "70%" }}>
                                            <li className="page-item">
                                                <a href="#" className="page-link" onClick={prePage}>
                                                    Prev
                                                </a>
                                            </li>
                                            {numbers.map((n, i) => (
                                                <li className={`page-item ${pageno === n ? "active" : ""}`} key={i}>
                                                    <a href="#" className="page-link " onClick={() => changePage(n)}>
                                                        {n}
                                                    </a>
                                                </li>
                                            ))}
                                            <li className="page-item">
                                                <a href="#" className="page-link" onClick={nextPage}>
                                                    Next
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    
                                    <Modal show={showModal} onHide={handleClose} centered>
                                        <Modal.Header >
                                            <Modal.Title>Sevas Offered</Modal.Title>
                                            <FaXmark onClick={handleClose} />
                                        </Modal.Header>
                                        <Modal.Body>
                                            <table>
                                                <thead>
                                                    <th style={{paddingBottom:"5%"}}>Seva Name  </th>
                                                    <th style={{paddingLeft:"5%",paddingBottom:"5%"}}>Amount</th>
                                                </thead>
                                                <tbody>
                                                    {sevasData && sevasData.length > 0 ? (
                                                        sevasData.map((data, index) => (
                                                            <tr>
 <td>
                                                               {data.seva_name}
                                                              
                                                            </td>
                                                            <td style={{paddingLeft:"5%"}}>
                                                                 Rs.{data.amount}
                                                            </td>
                                                            </tr>
                                                           
                                                        ))
                                                    ) : (
                                                        <p>No sevas available</p>
                                                    )}
                                                </tbody>
                                            </table>


                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="none" style={{ backgroundColor: "grey", color: 'white' }} onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                                
                            </div>

                        </div>
                        {loading && (
                                        <div className="loading-spinner-container" >
                                            <RotatingLines
                                                strokeColor="grey"
                                                strokeWidth="5"
                                                animationDuration="0.75"
                                                width="70"
                                                visible={true}
                                            />
                                        </div>
                                    )}
                    </div>
                   
                </div>
             
            </div>
            <style jsx>{`
                .loading-spinner-container {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                   
                    z-index: 9999;
                }
            `}</style>

        </div>
    );
};

export default Donations;
