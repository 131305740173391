const urls = {
	// LOGIN API's
	login: process.env.REACT_APP_BASE_URL + "/admin/login",

	create_leaders:process.env.REACT_APP_BASE_URL +"/admin/leaders/create",
	get_leaders:process.env.REACT_APP_BASE_URL + "/admin/leaders",
	update_leaders:process.env.REACT_APP_BASE_URL +"/admin/leaders/update",
	delete_leaders:process.env.REACT_APP_BASE_URL +"/admin/leaders/delete",

	create_volunteers:process.env.REACT_APP_BASE_URL +"/admin/volunteers/create",
	get_volunteers:process.env.REACT_APP_BASE_URL + "/admin/volunteers",
	update_volunteers:process.env.REACT_APP_BASE_URL +"/admin/volunteers/update",
	delete_volunteers:process.env.REACT_APP_BASE_URL +"/admin/volunteers/delete",
	get_donations:process.env.REACT_APP_BASE_URL +"/admin/donations",
	logout:process.env.REACT_APP_BASE_URL + "/admin/logout",
	reports_dashboard:process.env.REACT_APP_BASE_URL + "/admin/reports/dashboard",
	get_devotees:process.env.REACT_APP_BASE_URL +"/admin/devotees",
	send_sms:process.env.REACT_APP_BASE_URL +"/admin/volunteers/send-sms",
	volunteer_report:process.env.REACT_APP_BASE_URL +"/admin/reports/volunteers",
	volunteer_summary_report:process.env.REACT_APP_BASE_URL +"/admin/reports/volunteer-summary",
	donation_report:process.env.REACT_APP_BASE_URL +"/admin/reports/donations",
	revenue_report:process.env.REACT_APP_BASE_URL +"/admin/reports/revenue",
	top_five_volunteer_report:process.env.REACT_APP_BASE_URL +"/admin/reports/top-five-volunteers",
	top_five_sevas_report:process.env.REACT_APP_BASE_URL +"/admin/reports/top-five-sevas",
};

export default urls;
