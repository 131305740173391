import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";
import Layout from "./Components/Layout";
import Login from "./Pages/Login";
import { useState, useEffect } from "react";
import ChangePassword from "./Pages/ChangePassword";

import Dashboard from "./Pages/Dashboard";
import Acl from "./Pages/ACL";
import AclCreateForm from "./Pages/ACL/create.jsx";
import Acleditform from "./Pages/ACL/edit.jsx";

import ManageLeaders from "./Pages/ManageLeaders.js";
import "react-datepicker/dist/react-datepicker.css";
import "./Hooks/styles.css";
import ManageVolunteers from "./Pages/ManageVolunteers.js";
import Donations from "./Pages/Donations.js";
import Devotees from "./Pages/Devotees.js";
import ProtectedRoute from './MiddleWare/AuthMiddleWare';
import PageNotFound from "./Pages/PageNotFound.js";
import VolunteerReport from "./Pages/VolunteerReport.js";
import DonationsReport from "./Pages/DonationsReport.js";
import RevenueReport from "./Pages/RevenueReport.js";
import TopFiveVolunteerReport from "./Pages/TopFiveVolunteerReport.js";
import TopFiveSevasOptedReport from "./Pages/TopFiveSevasOptedReport.js";
import VolunteerSummaryReport from "./Pages/VolunteerSummaryReport.js";
function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const checkUserToken = () => {
		const userToken = localStorage.getItem('token');
		if (!userToken || userToken === 'undefined') {
			setIsLoggedIn(false);

		} else {
			setIsLoggedIn(true);
		}
		console.log(userToken)
	}
	useEffect(() => {
		checkUserToken();
	}, []);
	const router = createBrowserRouter([
		{
			element: isLoggedIn?<Navigate to="/dashboard" replace />:<Login/> ,
			path: "/"
		},
		{
			element: <Login />,
			path: "/login",
		},
		{
			path: "*",
			element: isLoggedIn ? <Navigate to="/login" replace /> : <Navigate to="/pagenotfound" replace />,
		},

		{
			element: <Layout />,
			children: [
				{
					path: "/dashboard",
					element: <ProtectedRoute><Dashboard /> </ProtectedRoute>,
				},
				{
					path: "/manageleaders",
					element: <ProtectedRoute><ManageLeaders /></ProtectedRoute>,
				},

				{
					path: "/managevolunteers",
					element: <ProtectedRoute><ManageVolunteers /> </ProtectedRoute>,
				},
				{
					path: "/donations",
					element: <ProtectedRoute> <Donations /></ProtectedRoute>,
				},
				
				{
					path: "/devotees",
					element: <ProtectedRoute><Devotees /> </ProtectedRoute>,
				},
				
				{
					path: "/volunteerreport",
					element: <ProtectedRoute> <VolunteerReport /></ProtectedRoute>,
				},
				{
					path: "/volunteersummaryreport",
					element: <ProtectedRoute> <VolunteerSummaryReport/></ProtectedRoute>,
				},
				
				{
					path: "/donationsreport",
					element: <ProtectedRoute> <DonationsReport /></ProtectedRoute>,
				},
				{
					path: "/revenuereport",
					element: <ProtectedRoute> <RevenueReport /></ProtectedRoute>,
				},
				{
					path: "/topfivevolunteerreport",
					element: <ProtectedRoute> <TopFiveVolunteerReport /></ProtectedRoute>,
				},
				{
					path: "/topfivesevasoptedreport",
					element: <ProtectedRoute> <TopFiveSevasOptedReport /></ProtectedRoute>,
				},
				{
					path: "/access-control-lists",
					element: <Acl />,
				},
				{
					path: "/access-control-lists-create",
					element: <AclCreateForm />,
				},
				{
					path: "/access-control-lists-edit/:id",
					element: <Acleditform />,
				},

				{
					path: "/changepassword",
					element: <ProtectedRoute><ChangePassword /> </ProtectedRoute>,
				},
				,
				{
					path: "/pagenotfound",
					element: <ProtectedRoute> <PageNotFound /> </ProtectedRoute>
				}
			],
		},
	]);

	return (
		<div>
			<RouterProvider router={router} />
		</div>
	);
}

export default App;
