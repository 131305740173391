import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import Navbar from "../Components/Navbar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "../interceptor";
import urls from "../endpoint";
import { Row, Col } from "react-bootstrap";
import { FaXmark } from "react-icons/fa6";
import { FaRotate } from "react-icons/fa6";
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { FaDownload } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
const DonationsReport = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageno, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [searchVolunteer, setSearchVolunteer] = useState(null);
  const MAX_PAGES_DISPLAYED = 5;
  const [totalpages, setTotalPages] = useState([]);
  const currentPage = totalpages.current_page;
  const npages = totalpages.last_page ? totalpages.last_page : 0;
  const srNo = currentPage * 10 - 10 + 1;
  const startPage = Math.max(1, pageno - Math.floor(MAX_PAGES_DISPLAYED / 2));
  const endPage = Math.min(npages, startPage + MAX_PAGES_DISPLAYED - 1);
  const numbers = [...Array(endPage - startPage + 1).keys()].map(
    (i) => startPage + i
  );
  const currentDate = new Date().toISOString().split("T")[0];
  const [searchParams, setSearchParams] = useSearchParams();
  const [DonationDetailsForTable, setDonationDetailsForTable] = useState();
  const [DonationDataDateWise, setDonationDataDateWise] = useState({
    start_date: currentDate,
    end_date: currentDate,
  });
  const [showModal, setShowModal] = useState(false);

  const handleShow = (data) => {
    setShowModal(true);
  };
  const handleClose = () => setShowModal(false);
  useEffect(() => {
    getDonationReportData();
  }, [DonationDataDateWise]);
  useEffect(() => {
    if ((pageno, rowsPerPage)) {
      getDonationReportData();
    }
  }, [pageno, rowsPerPage]);

  const dateHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setDonationDataDateWise((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getDonationReportDataReload = () => {
    window.location.reload()
    setLoading(true);
    setIsClicked(true);
    setDonationDataDateWise({
      start_date: currentDate,
      end_date: currentDate,
    });
    axios
      .post(urls.donation_report, {
        start_date: currentDate,
        end_date: currentDate,
        page: pageno,
        paginate: 1
      })
      .then((resp) => {
        const respdata = resp.data;
        console.log(respdata.data);

        setDonationDetailsForTable(resp.data.data);
        setTotalPages(respdata.data);
        setLoading(false);
        setIsClicked(false);
      })
      .catch((error) => {
        console.error("Error fetching Donation:", error);
        setLoading(false);
        setIsClicked(false);
      });
  };

  // const handleVolunteerSearch = () => {
  //   if(searchVolunteer.length > 3){
  //     axios
  //     .post(urls.donation_report, {
  //       query: searchVolunteer,
  //       start_date: DonationDataDateWise.start_date,
  //       end_date: DonationDataDateWise.end_date,
  //       page: pageno,
  //       paginate: 1
  //     })
  //     .then((resp) => {
  //       const respdata = resp.data;
  //       console.log(respdata.data, "hello");

  //       setDonationDetailsForTable(resp.data.data);
  //       setTotalPages(respdata.data.donations);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setDonationDetailsForTable([]);
  //       console.error("Error fetching Donation:", error);
  //       setLoading(false);
  //     });
  //   }else{
  //     getDonationReportData()
  //   }
   
  // }

  const getDonationReportData = () => {
    let params = {}
    console.log(searchVolunteer, "volun")
    if(searchVolunteer){
      if(searchVolunteer.length > 3){
        setSearchParams({
          'start_date': DonationDataDateWise.start_date,
          'end_date': DonationDataDateWise.end_date,
          'page': pageno,
          'paginate': 1,
          'query': searchVolunteer
        })
        params = {
          query: searchVolunteer,
          start_date: DonationDataDateWise.start_date,
          end_date: DonationDataDateWise.end_date,
          page: pageno,
          paginate: 1
        }
      }else{
        changePage(1)
        params = {
            start_date: DonationDataDateWise.start_date,
            end_date: DonationDataDateWise.end_date,
            page: pageno,
            paginate: 1
        }
    }
    }else if(paymentStatus){
      setSearchParams({
        'start_date': DonationDataDateWise.start_date,
        'end_date': DonationDataDateWise.end_date,
        'page': pageno,
        'paginate': 1,
        'payment_status': paymentStatus
      })
     params =  {
        start_date: DonationDataDateWise.start_date,
        end_date: DonationDataDateWise.end_date,
        payment_status: paymentStatus,
        page: pageno,
        paginate: 1
      }
    }else {
      setSearchParams({
        'start_date': DonationDataDateWise.start_date,
        'end_date': DonationDataDateWise.end_date,
        'page': pageno,
        'paginate': 1
      })
      params = {
        start_date: DonationDataDateWise.start_date,
        end_date: DonationDataDateWise.end_date,
        page: pageno,
        paginate: 1
      }
    }
    
    setLoading(true);
    axios
      .post(urls.donation_report, params)
      .then((resp) => {
        const respdata = resp.data;
        console.log(respdata.data, "hello");

        setDonationDetailsForTable(resp.data.data);
        setTotalPages(respdata.data.donations);
        setLoading(false);
      })
      .catch((error) => {
        setDonationDetailsForTable([]);
        console.error("Error fetching Donation:", error);
        setLoading(false);
      });
  };

  const getDonationReportDataDownload = () => {
    if(searchVolunteer){
      setLoading(true);
      axios
        .post(urls.donation_report, {
          start_date: DonationDataDateWise.start_date,
          end_date: DonationDataDateWise.end_date,
          query: searchVolunteer,
          response_type: "xlsx"
        })
        .then((resp) => {
          
  
          const url = resp.data.data.link;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "report.xls");
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        })
        .catch((error) => {
          
          console.error("Error fetching Donation:", error);
          setLoading(false);
        });
    }else if(paymentStatus){
      setLoading(true);
      axios
        .post(urls.donation_report, {
          start_date: DonationDataDateWise.start_date,
          end_date: DonationDataDateWise.end_date,
          payment_status: paymentStatus,
          response_type: "xlsx"
        })
        .then((resp) => {
          
  
          const url = resp.data.data.link;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "report.xls");
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        })
        .catch((error) => {
          
          console.error("Error fetching Donation:", error);
          setLoading(false);
        });
    }else{
      setLoading(true);
      axios
        .post(urls.donation_report, {
          start_date: DonationDataDateWise.start_date,
          end_date: DonationDataDateWise.end_date,
          response_type: "xlsx"
        })
        .then((resp) => {
          
  
          const url = resp.data.data.link;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "report.xls");
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        })
        .catch((error) => {
          
          console.error("Error fetching Donation:", error);
          setLoading(false);
        });
    }
   
  };
  const prePage = () => {
    if (pageno !== 1) {
      setPageNo(pageno - 1);
    }
  };
  const changePage = (id) => {
    console.log(id);
    setPageNo(id);
  };

  const nextPage = () => {
    console.log("pageno", pageno);
    console.log("npgaes", npages);
    if (pageno !== npages) {
      setPageNo(pageno + 1);
    }
  };
  const options = [
    { value: "paid", label: "Paid" },
    { value: "unpaid", label: "Unpaid" },
  ];

  useEffect(() => {
    if(paymentStatus){
      getDonationReportData();
    }
  }, [paymentStatus, DonationDataDateWise]);

  const handleChange = (selectedOption) => {
    console.log("Selected option:", selectedOption);
    setPaymentStatus(selectedOption.value);
  };

  // const getDonationReportByPaymentStatus = () => {
  //   if(paymentStatus){
  //     axios
  //     .post(urls.donation_report, {
  //       start_date: DonationDataDateWise.start_date,
  //       end_date: DonationDataDateWise.end_date,
  //       payment_status: paymentStatus,
  //       page: pageno,
  //       paginate: 1
  //     })
  //     .then((resp) => {
  //       const respdata = resp.data;
  //       console.log(respdata.data, "hello");

  //       setDonationDetailsForTable(resp.data.data);
  //       setTotalPages(respdata.data.donations);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setDonationDetailsForTable([]);
  //       console.error("Error fetching Donation:", error);
  //       setLoading(false);
  //     });
  //   }else{
  //     getDonationReportData()
  //   }
  // }

  return (
    <div className="app">
      <div className="layout">
        <Navbar />
        <Sidebar />
        <div className="page-container dynamic-bgcolor">
          <div>
            <div className="main-content">
              <div style={{ marginTop: "-2%" }}>
                <h3 className="text-center">Donations Report</h3>

                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
                <Row className="mt-5" style={{ marginTop: "3%" }}>
                  <Col md={2} style={{ marginTop: "1%" }}>
                    <label>Start Date</label>
                    <input
                      type="date"
                      className={`form-control`}
                      onChange={dateHandler}
                      name="start_date"
                      value={DonationDataDateWise.start_date}
                    />
                  </Col>

                  <Col md={2} style={{ marginTop: "1%" }}>
                    <label>End Date</label>
                    <input
                      type="date"
                      className={`form-control`}
                      onChange={dateHandler}
                      name="end_date"
                      value={DonationDataDateWise.end_date}
                    />
                  </Col>
                  <Col md={2} style={{ marginTop: "1%" }}>
                    <label>Select Payment Status</label>
                    <Select
                      options={options}
                      onChange={handleChange}
                      defaultValue={paymentStatus}
                      placeholder="Select an option"
                    />
                  </Col>
                  <Col md={3} style={{ marginTop: "1%" }}>
                  <label>Search By Volunteer Name</label>
                  <input
                      type="text"
                      placeholder="search by volunteer name"
                      className={`form-control`}
                      name="search"
                      onChange={(e) => setSearchVolunteer(e.target.value)}
                      onKeyUp={getDonationReportData}
                    />
                  </Col>
                  <Col md={2} style={{ marginTop: "3%" }}>
                    <FaDownload
                      onClick={getDonationReportDataDownload}
                      title="Download Report"
                      size={25}
                    />
                  </Col>
                  <Col md={1} style={{  marginTop: "3%" }}>
                    <FaRotate
                      onClick={getDonationReportDataReload}
                      title="Refresh Data"
                      size={25}
                    />
                  </Col>
                </Row>
                {DonationDetailsForTable && DonationDetailsForTable.donations &&
                DonationDetailsForTable.donations.data.length > 0 ? (
                  // <div style={{ marginTop: "2%",justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column' }} className="text-center">
                  //   <h3 >
                  //     Total Revenue : {DonationDetailsForTable.amount_received}{" "}
                  //   </h3>
                  <div
                    style={{
                      marginTop: "2%",
                      overflowX: "auto",
                      overflowY: "hidden",
                    }}
                  >
                    <table className="table mt-2">
                      <thead>
                        <tr>
                          <th>Donation No</th>
                          <th>Devotee Name</th>
                          <th>Devotee Mobile No</th>
                          <th>Volunteer Name</th>
                          <th>Leader Name</th>
                          <th>Festival Name</th>
                          <th>Total Amount</th>
                          <th>Payment Method</th>
                          <th>Payment Id</th>
                          <th>Payment Status</th>
                        </tr>
                      </thead>
                      {console.log(DonationDetailsForTable)}
                      <tbody>
                        {DonationDetailsForTable &&
                          DonationDetailsForTable.donations.data.length > 0 &&
                          DonationDetailsForTable.donations.data.map((data, index) => (
                            <tr key={index}>
                              <td>{data.donation_no}</td>
                              <td>{data.devotee?.name}</td>
                              <td>{data.devotee?.mobile_no}</td>
                              <td>{data.volunteer.name}</td>
                              <td>{data.leader.name}</td>
                              <td>{data.festival_name}</td>
                              <td>{data.total_amount}</td>
                              <td>{data.payment_method}</td>
                              <td>{data.payment_id}</td>
                              <td>{<b>{data.payment_status}</b>}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    <div>
                      <ul className="pagination" style={{ marginLeft: "70%" }}>
                        <li className="page-item">
                          <a href="#" className="page-link" onClick={prePage}>
                            Prev
                          </a>
                        </li>
                        {numbers.map((n, i) => (
                          <li
                            className={`page-item ${
                              pageno === n ? "active" : ""
                            }`}
                            key={i}
                          >
                            <a
                              href="#"
                              className="page-link "
                              onClick={() => changePage(n)}
                            >
                              {n}
                            </a>
                          </li>
                        ))}
                        <li className="page-item">
                          <a href="#" className="page-link" onClick={nextPage}>
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                ) : (
                  <div
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      marginTop: "13%",
                    }}
                  >
                    <h4>No Data Available</h4>
                  </div>
                )}
              </div>
            </div>
            {loading && (
              <div className="loading-spinner-container">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="70"
                  visible={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .loading-spinner-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          z-index: 9999;
        }
      `}</style>
    </div>
  );
};

export default DonationsReport;
