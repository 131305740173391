import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import endpoints from "../endpoint.js";
import banner from "../assets/03.JPG";
import axios from "axios";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { MdPerson } from "react-icons/md";
import logo from '../assets/Iskcon-_full-logo-black-2.png'
import "../Hooks/Login.scss";

function Login() {
	let navigate = useNavigate();
	const [userName, setUserName] = useState();
	const [password, setPassword] = useState();
    const [formErrors,setFormErrors]=useState({})
	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	async function handleSubmitted() {
		// navigate("/dashboard");
		try {
			let payload = { username: userName, password: password };

			setLoading(true);

			const res = await axios.post(endpoints.login, payload);
			if (res.status === 200) {
				//  console.log(res.data.data.access_token);
				const storing_merchant_details = JSON.stringify(res.data.data.user);
				localStorage.setItem("userData", storing_merchant_details);
				const token = JSON.stringify(res.data.data.access_token);
				localStorage.setItem("token", token);
				setLoading(false);
				navigate("/dashboard");
			} else {
				console.log("Error: Unable to fetch API");
			}
		} catch (error) {
			setLoading(false);
			console.log(
				error.response
			);
			if ( error.response && error.response.data && error.response.data.data&& error.response.data.data.length === 0) {
                       
				setFormErrors(error.response.data);
				console.log(error.response.data.message);
			} else if (error.response && error.response.data && error.response.data.data&& error.response.data.data.length !== 0) {
			   
				setFormErrors(error.response.data.data);
			} 
		}
	}

	return (
		<>
			<div className="row " style={{ height: "100vh" }}>
				
				<div className="col-md-6" style={{ height: "100%" }}>
					<div className="auth__right">
						<img src={banner} alt="loginImage" />
					</div>
				</div>
				<div className="col-md-6" style={{ height: "100%" }}>
					<div className="auth">
						<div className="auth__left">
							<div className="auth__left__container">
								<img
									src={logo}
									alt="avadoha-logo"
									style={{width:"80%",height:"50%",marginLeft:'-2%'}}
								/>

								<div className="auth__left__container__text">
									<h2>Welcome Back!</h2>
									<p>Please Log in to your account.</p>
								</div>

								<div className="auth__left__container__form">
									<div className="auth__left__container__fdiv">
										<div className="fdiv__field1">
											<label>Username</label>
										</div>

										<div className="fdiv__field2 normal_input">
											<div>
												<MdPerson className="fdiv__icon" />
											</div>

											<input
												onChange={(e) => {
													setUserName(e.target.value);
													setFormErrors((prevFormErrors)=>({
                                                      ...prevFormErrors,
													   "username":null
													}))
												}}
												type="text"
												// className="login_input"
												placeholder="Username"
											/>
										</div>
									</div>
									{ formErrors && formErrors.username && <div className='is-invalid'>{formErrors.username}</div>}
								</div>
								<div className="auth__left__container__form">
									<div className="auth__left__container__fdiv">
										<div className="fdiv__field1">
											<label>Password</label>
										</div>

										<div className="fdiv__field2 normal_input">
											<div
												onClick={() => {
													setShowPassword(!showPassword);
												}}
											>
												{showPassword ? (
													<AiFillEyeInvisible
														className="fdiv__icon"
														style={{
															cursor: "pointer",
														}}
													/>
												) : (
													<AiFillEye
														className="fdiv__icon"
														style={{
															cursor: "pointer",
														}}
													/>
												)}
											</div>

											<input
												type={showPassword ? "text" : "password"}
												onChange={(e) => {
													setFormErrors((prevFormErrors)=>({
														...prevFormErrors,
														 "password":null
													  }))
													  setPassword(e.target.value)
												}}
												placeholder="Password"
												// className="login_input"
											/>
										</div>
										{formErrors && formErrors.password && <div className='is-invalid'>{formErrors.password}</div>}

									</div>

								</div>
                                 { formErrors && formErrors.message && <div className='is-invalid'>{formErrors.message}</div>}
								<div className="auth__buttonsection">
								
									

									{loading ? (
										<button
											type="button"
											className="btn dynamic-color login_button_testcolor"
											style={{
												width: "100%",
												color: "#ffffff",
												background:"#53047b"
											}}
											onClick={handleSubmitted}
											disabled
										>
											Signing in &nbsp;
											<i className="fas fa-spinner fa-spin"></i>
										</button>
									) : (
										<button
											type="button"
											className="btn dynamic-color login_button_testcolor"
											style={{ width: "100%" }}
											onClick={handleSubmitted}
										>
											Sign in
										</button>
									)}
								</div>
								{/* <div style={{ marginTop: "5vh" }}>
									<p style={{ color: "black" }}>
										Powered by Sassymonks Pvt Ltd
									</p>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Login;
