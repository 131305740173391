import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import Navbar from "../Components/Navbar";
import axios from "../interceptor";
import urls from "../endpoint";
import { Row, Col } from "react-bootstrap";
import { FaRotate } from "react-icons/fa6";
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactApexChart from "react-apexcharts";
import { FaDownload } from "react-icons/fa";

const TopFiveVolunteerReport = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageno, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sevasData, setSevasData] = useState([]);
  const MAX_PAGES_DISPLAYED = 5;
  const [totalpages, setTotalPages] = useState([]);
  const currentPage = totalpages.current_page;
  const npages = totalpages.last_page ? totalpages.last_page : 0;
  const srNo = currentPage * 10 - 10 + 1;
  const startPage = Math.max(1, pageno - Math.floor(MAX_PAGES_DISPLAYED / 2));
  const endPage = Math.min(npages, startPage + MAX_PAGES_DISPLAYED - 1);
  const numbers = [...Array(endPage - startPage + 1).keys()].map(
    (i) => startPage + i
  );
  const currentDate = new Date().toISOString().split("T")[0];
  const [TopFiveVolunteerDetailsForTable, setTopFiveVolunteerDetailsForTable] =
    useState([]);
  const [TopFiveVolunteerDataDateWise, setTopFiveVolunteerDataDateWise] =
    useState({
      start_date: currentDate,
      end_date: currentDate,
    });
  const [showModal, setShowModal] = useState(false);
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "TopFiveVolunteer-chart",
      },
      xaxis: {
        categories: [],
      },
      tooltip: {
        y: {
          formatter: function (
            val,
            { series, seriesIndex, dataPointIndex, w }
          ) {
            const donationsCount =
              w.config.series[seriesIndex].data[dataPointIndex].donationsCount;
            return `${val} Donations (${donationsCount} donations)`;
          },
        },
      },
    },
    series: [
      {
        name: "Amount Collected",
        data: [],
      },
    ],
  });

  const handleShow = (data) => {
    setShowModal(true);
  };
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    getTopFiveVolunteerReportData();
  }, [TopFiveVolunteerDataDateWise, pageno, rowsPerPage]);

  useEffect(() => {
    if (TopFiveVolunteerDetailsForTable.length > 0) {
      setChartData({
        options: {
          ...chartData.options,
          xaxis: {
            categories: TopFiveVolunteerDetailsForTable.map(
              (item) => item.volunteer_name
            ),
          },
          title: {
            text: `Top 5 Volunteers from ${TopFiveVolunteerDataDateWise.start_date} to ${TopFiveVolunteerDataDateWise.end_date}`,
            align: "center",
          },
        },
        series: [
          {
            name: "Amount Collected",
            data: TopFiveVolunteerDetailsForTable.map((item) => ({
              x: item.volunteer_name,
              y: item.amount_collected,
              donationsCount: item.donations_count,
            })),
          },
        ],
      });
    }
  }, [TopFiveVolunteerDetailsForTable, TopFiveVolunteerDataDateWise]);

  const dateHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setTopFiveVolunteerDataDateWise((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getTopFiveVolunteerReportDataReload = () => {
    setLoading(true);
    setIsClicked(true);
    setTopFiveVolunteerDataDateWise({
      start_date: currentDate,
      end_date: currentDate,
    });
    axios
      .post(urls.top_five_volunteer_report, {
        start_date: currentDate,
        end_date: currentDate,
        page: pageno,
        paginate: 1,
      })
      .then((resp) => {
        const respdata = resp.data;
        setTopFiveVolunteerDetailsForTable(resp.data.data);
        setTotalPages(respdata.data);
        setLoading(false);
        setIsClicked(false);
      })
      .catch((error) => {
        console.error("Error fetching TopFiveVolunteer:", error);
        setLoading(false);
        setIsClicked(false);
      });
  };

  const getTopFiveVolunteerReportData = () => {
    setLoading(true);
    axios
      .post(urls.top_five_volunteer_report, {
        start_date: TopFiveVolunteerDataDateWise.start_date,
        end_date: TopFiveVolunteerDataDateWise.end_date,
        page: pageno,
        paginate: 1,
      })
      .then((resp) => {
        const respdata = resp.data;
        console.log(respdata);
        setTopFiveVolunteerDetailsForTable(resp.data.data);
        setTotalPages(respdata.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching TopFiveVolunteer:", error);
        setTopFiveVolunteerDetailsForTable([]);
        setLoading(false);
      });
  };
  const getTopFiveVolunteerReportDataDownload = () => {
    setLoading(true);
    axios
      .post(urls.top_five_volunteer_report, {
        start_date: TopFiveVolunteerDataDateWise.start_date,
        end_date: TopFiveVolunteerDataDateWise.end_date,
        response_type: "xlsx"
      })
      .then((resp) => {
        
        const url = resp.data.data.link;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.xls");
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((error) => {
      
        setLoading(false);
      });
  };

  return (
    <div className="app">
      <div className="layout">
        <Navbar />
        <Sidebar />
        <div className="page-container dynamic-bgcolor">
          <div>
            <div className="main-content">
              <div style={{ marginTop: "-2%" }}>
                <h3 className="text-center">Top 5 Volunteer Report</h3>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
                <Row className="mt-5" style={{ marginTop: "3%" }}>
                  <Col md={2} style={{ marginTop: "1%" }}>
                    <label>Start Date</label>
                    <input
                      type="date"
                      className={`form-control`}
                      onChange={dateHandler}
                      name="start_date"
                      value={TopFiveVolunteerDataDateWise.start_date}
                    />
                  </Col>
                  <Col md={2} style={{ marginTop: "1%" }}>
                    <label>End Date</label>
                    <input
                      type="date"
                      className={`form-control`}
                      onChange={dateHandler}
                      name="end_date"
                      value={TopFiveVolunteerDataDateWise.end_date}
                    />
                  </Col>
                  <Col style={{ marginLeft: "50%", marginTop: "3%" }}>
                    <FaDownload
                      onClick={getTopFiveVolunteerReportDataDownload}
                      title="Download Report"
                      size={25}
                    />
                  </Col>
                  <Col style={{ marginTop: "3%" }}>
                    <FaRotate
                      onClick={getTopFiveVolunteerReportDataReload}
                      title="Refresh Data"
                      size={25}
                    />
                  </Col>
                </Row>
                {TopFiveVolunteerDetailsForTable &&
                TopFiveVolunteerDetailsForTable.length !== 0 ? (
                  <div style={{ marginTop: "5%",justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column'  }}>
                  <div className="card bg-white rounded shadow mt-5" >
                  <div className="card-body">
                    <ReactApexChart
                      options={{
                        ...chartData.options,
                        chart: {
                          toolbar: {
                            show: false,
                          },
                        },
                      }}
                      series={chartData.series}
                      type="bar"
                      height={300}
                      width={1100}
                    />
                    </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      marginTop: "13%",
                    }}
                  >
                    <h4>No Data Available</h4>
                  </div>
                )}
              </div>
            </div>
            {loading && (
              <div className="loading-spinner-container">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="70"
                  visible={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .loading-spinner-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
        }
      `}</style>
    </div>
  );
};

export default TopFiveVolunteerReport;
